/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { Stripe } from 'stripe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import {
  SearchIcon,
  BookmarkIcon,
  CalendarIcon,
  CheckCircleIcon,
  ExclamationIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/solid';
import {
  IBooking,
  IUnit,
  IUser,
  IPeriod,
  IPayment,
  IPaymentType,
  IBookingOption,
} from '../types';
import {
  GET_BOOKINGS,
  CREATE_BOOKING,
  UPDATE_BOOKING,
  DELETE_BOOKING,
  CANCEL_BOOKING,
  RESEND_EMAILS,
  DELETE_BOOKINGS,
} from '../graphql/booking';
import { GET_OPTIONS } from '../graphql/option';
import useDebounce from '../components/hooks/useDebounce';
import { GET_PAYMENTTYPES } from '../graphql/paymentType';
import { GET_AVAILABILITY, GET_BOOKING_INFO } from '../graphql/availability';
import { GET_PERIODS } from '../graphql/period';
import { GET_UNITS } from '../graphql/unit';
import Calender from '../components/calender';
import Button from '../components/button';
import {
  CREATE_PAYMENT,
  CREATE_STRIPE_PAYMENT,
  DELETE_PAYMENT,
  GET_PAYMENTS,
  REFUND_PAYMENT,
  RESEND_GUARANTEE,
  UPDATE_PAYMENT,
} from '../graphql/payment';
import { Combobox, ComboboxItem } from '../components/comboBox';
import { Switch } from '../components/switch';
import { Input } from '../components/input';
import { Select } from '../components/select';
import { Textarea } from '../components/textarea';
import { PageHeader } from '../components/pageheader';
import { INavItem } from '../components/navbar';
import { Notification } from '../components/notification';
import { Slideover } from '../components/slideover';
import { Dialog } from '../components/dialog';
import { Table, IColumn } from '../components/table';
import { Filters, IFilter } from '../components/filters';

const Status: React.FC<{ status: string }> = ({ status }) => {
  let statusspan = status;
  let bg = 'bg-gray-200';

  switch (status) {
    case 'open':
      statusspan = 'Open';
      bg = 'bg-blue-500';
      break;
    case 'partial-paid':
      statusspan = 'Gedeeltelijk';

      break;
    case 'guarantee-open':
      statusspan = 'Waarborg open';
      break;
    case 'complete':
      statusspan = 'Volledig';
      bg = 'bg-green-500';
      break;
    case 'canceled':
      statusspan = 'Geannuleerd';
      bg = 'bg-red-500';
      break;
    case 'guarantee-refunded':
      statusspan = 'Terugbetaald';
      bg = 'bg-teal-500';
      break;
    default:
      break;
  }
  return (
    <div className={`px-2 py-1 rounded-lg inline-block ${bg}`}>
      {statusspan}
    </div>
  );
};

const PaymentStatus: React.FC<{
  status?:
    | 'canceled'
    | 'open'
    | 'paid'
    | 'refunded'
    | 'credited'
    | 'not-send'
    | 'partial-refund'
    | 'late';
}> = ({ status = 'not-send' }) => {
  let statusspan: any = status;
  let bg = 'bg-gray-200 text-black';
  switch (status) {
    case 'not-send':
      statusspan = 'Niet verstuurd';
      bg = 'bg-gray-200 text-black';
      break;
    case 'paid':
      statusspan = 'Betaald';
      bg = 'bg-green-500 text-white';
      break;
    case 'late':
      statusspan = 'Te laat';
      bg = 'bg-red-500 text-white';
      break;
    case 'open':
      statusspan = 'Gefactureerd';
      bg = 'bg-blue-500 text-white';
      break;
    case 'credited':
      statusspan = 'Gecrediteerd';
      bg = 'bg-red-500 text-black';
      break;
    case 'refunded':
      statusspan = 'Terugbetaald';
      bg = 'bg-red-500 text-black';
      break;
    case 'partial-refund':
      statusspan = 'Gedeeltelijk terugbetaald';
      bg = 'bg-teal-500';
      break;
    default:
      break;
  }
  return (
    <div
      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${bg}`}
    >
      {statusspan}
    </div>
  );
};

const PaymentTable: React.FC<{
  booking: IBooking;
  openDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setSelection: React.Dispatch<React.SetStateAction<IPayment>>;
}> = ({ booking, openDialog, setSelection }) => {
  if (!booking || !booking.payments || booking.payments.length === 0)
    return (
      <span>Er zijn nog geen betalingen aangemaakt voor deze boeking.</span>
    );

  const { payments } = booking;

  return (
    <div className='bg-white shadow overflow-hidden sm:rounded-md'>
      <ul className='divide-y divide-gray-200'>
        {payments &&
          payments.map(item => {
            const { stripe, mollie } = item;
            let invoiceData: any;
            let refundData: any;
            let creditData: any;
            let intentData: any;
            let dueDate: number | string;
            let dueDateParsed: Moment | undefined;
            let pastDueDate = false;
            let isPaid = false;
            let datePaid;
            const now = moment();
            if (stripe) {
              const { invoice, refund, credit, intent } = stripe;
              if (invoice) {
                try {
                  invoiceData = JSON.parse(invoice);
                  dueDate = invoiceData!.due_date;
                  pastDueDate =
                    dueDate && typeof dueDate === 'number'
                      ? moment.unix(dueDate).isBefore(now)
                      : false;
                  dueDateParsed =
                    dueDate && typeof dueDate === 'number'
                      ? moment.unix(dueDate)
                      : undefined;
                  isPaid =
                    invoiceData!.paid && !(item.creditId || item.refundId);
                } catch (err) {
                  console.error(err);
                }
              }
              if (refund) {
                try {
                  refundData = JSON.parse(refund);
                } catch (err) {
                  console.error(err);
                }
              }
              if (credit) {
                try {
                  creditData = JSON.parse(credit);
                } catch (err) {
                  console.error(err);
                }
              }
              if (intent) {
                try {
                  intentData = JSON.parse(intent);
                  isPaid = intentData!.status === 'succeeded';
                } catch (err) {
                  console.error(err);
                }
              }
            } else if (mollie) {
              const { invoice, refund, credit, intent } = mollie;
              if (invoice) {
                try {
                  invoiceData = JSON.parse(invoice);
                  dueDate = invoiceData!.date_overdue || undefined;
                  pastDueDate =
                    dueDate && typeof dueDate === 'string'
                      ? moment(dueDate).isBefore(now)
                      : false;
                  dueDateParsed =
                    dueDate && typeof dueDate === 'string'
                      ? moment(dueDate)
                      : undefined;
                  isPaid =
                    invoiceData!.paid && !(item.creditId || item.refundId);
                } catch (err) {
                  console.error(err);
                }
              }
              if (refund) {
                try {
                  refundData = JSON.parse(refund);
                } catch (err) {
                  console.error(err);
                }
              }
              if (credit) {
                try {
                  creditData = JSON.parse(credit);
                } catch (err) {
                  console.error(err);
                }
              }
              if (intent) {
                try {
                  intentData = JSON.parse(intent);
                  isPaid = intentData!.status === 'paid';
                } catch (err) {
                  console.error(err);
                }
              }
            }

            return (
              <li key={`payment_${item.id}`}>
                <div
                  role='menuitem'
                  className='block hover:bg-gray-50 hover:cursor-pointer'
                  onClick={() => {
                    setSelection(item);
                    openDialog(true);
                  }}
                  tabIndex={0}
                  onKeyPress={() => {
                    setSelection(item);
                    openDialog(true);
                  }}
                >
                  <div className='px-4 py-4 sm:px-6'>
                    <div className='flex items-center justify-between'>
                      <p className='text-sm font-medium text-indigo-600 truncate'>
                        {item.type && item.type.label}{' '}
                        <span className='text-indigo-200'>({item.id})</span>
                      </p>
                      <div className='ml-2 flex-shrink-0 flex'>
                        <PaymentStatus status={item.status} />
                      </div>
                    </div>
                    <div className='mt-2 sm:flex sm:justify-between'>
                      <div className='sm:flex'>
                        <p className='flex items-center text-sm text-gray-500'>
                          <BookmarkIcon
                            className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400'
                            aria-hidden='true'
                          />
                          <span className='mr-1'>
                            {item.stripe && item.stripe.invoice
                              ? 'aangemaakt op'
                              : 'wordt aangemaakt op'}{' '}
                          </span>
                          <time dateTime={item.dateDue}>
                            {moment(item.dateDue).format('DD-MM-YYYY')}
                          </time>
                        </p>
                      </div>
                      <div className='mt-2 flex items-center text-sm text-gray-500 sm:mt-0'>
                        {!isPaid &&
                          dueDateParsed &&
                          !(item.creditId || item.refundId) && (
                            <>
                              <CalendarIcon
                                className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400'
                                aria-hidden='true'
                              />
                              <p>
                                vervalt op{' '}
                                <time dateTime={dueDateParsed.toISOString()}>
                                  {dueDateParsed.format('DD-MM-YYYY')}
                                </time>
                              </p>
                            </>
                          )}
                        {isPaid && (
                          <>
                            <CheckCircleIcon
                              className='flex-shrink-0 mr-1.5 h-5 w-5 text-green-400'
                              aria-hidden='true'
                            />
                            <p>betaald </p>
                          </>
                        )}
                        {refundData && (
                          <>
                            <CheckCircleIcon
                              className='flex-shrink-0 mr-1.5 h-5 w-5 text-blue-400 ml-2'
                              aria-hidden='true'
                            />
                            <p>(gedeeltelijk) terugbetaald </p>
                          </>
                        )}
                        {!isPaid && !item.dateDue && <p>niet aangemaakt</p>}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

const getBookingStatus = (booking: IBooking): string => {
  if (booking.status === 'canceled') return 'canceled';
  if (booking.payments && booking.payments.length > 0) {
    let deposit: IPayment | undefined;
    let total: IPayment | undefined;
    let guarantee: IPayment | undefined;
    let remainder: IPayment | undefined;

    let status = 'open';
    let otherPaymentStatus: string | undefined;
    let allPaymentsPaid = true;
    let unPaidInvoices = 0;

    for (let i = 0; i < booking.payments.length; i += 1) {
      const payment = booking.payments[i];

      switch (
        payment.type!.id // payment type is required by database ingoring this is safe.
      ) {
        case 1:
          allPaymentsPaid = !!payment.isPaid;
          unPaidInvoices += payment.isPaid ? 0 : 1;
          deposit = payment;
          break;
        case 2:
          allPaymentsPaid = !!payment.isPaid;
          unPaidInvoices += payment.isPaid ? 0 : 1;
          remainder = payment;
          break;
        case 3:
          allPaymentsPaid = !!payment.isPaid;
          unPaidInvoices += payment.isPaid ? 0 : 1;
          guarantee = payment;
          break;
        case 4:
          allPaymentsPaid = !!payment.isPaid;
          unPaidInvoices += payment.isPaid ? 0 : 1;
          total = payment;
          break;
        default:
          allPaymentsPaid = !!payment.isPaid;
          unPaidInvoices += payment.isPaid ? 0 : 1;
          otherPaymentStatus = payment.isPaid ? 'paid' : 'open';
          break;
      }
    }

    if (booking.status !== 'canceled') status = 'open';
    if (booking.status === 'canceled') {
      status = 'canceled';
      return status;
    }
    if (allPaymentsPaid) {
      status = 'complete';
      if (guarantee && guarantee.isPaid && guarantee.isRefunded) {
        status = 'guarantee-refunded';
      }
      return status;
    }
    if (unPaidInvoices === 1) {
      status = 'partial-paid';
      if (booking.payments.length === 1) status = 'open';
      if (guarantee && !guarantee.isPaid) status = 'guarantee-open';
      return status;
    }

    if (unPaidInvoices > 1 && unPaidInvoices === booking.payments.length) {
      status = 'open';
      return status;
    }

    if (unPaidInvoices > 1 && unPaidInvoices < booking.payments.length) {
      status = 'partial-paid';
      return status;
    }

    return status;
  }

  return 'complete';
};

function removeLeadingZeros(str: string): string {
  // Regex to remove leading
  // zeros from a string

  // Replaces the matched
  // value with given string
  const newStr = str.replaceAll('/^0+(?!$)/g', '');

  return newStr;
}

function ValidateEmail(input: string) {
  const validRegex =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (input.match(validRegex)) {
    return true;
  }

  return false;
}

interface ICreateUpdatePayment {
  payment?: IPayment;
  handleChange: React.Dispatch<React.SetStateAction<IPayment>>;
  isOpenRefundDialog: boolean;
  setOpenRefundDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenPayment: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDeletePaymentDialog: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenDeletePaymentDialog: boolean;
  deletePayment: any;
}

const CreateUpdatePaymentForm: React.FC<ICreateUpdatePayment> = ({
  payment,
  isOpenRefundDialog,
  setOpenRefundDialog,
  setOpenDeletePaymentDialog,
  isOpenDeletePaymentDialog,
  deletePayment,
  handleChange,
  setOpenPayment,
}) => {
  const [partialRefundText, setPartialRefundText] = useState<
    string | undefined
  >(undefined);
  const [partialRefundAmount, setPartialRefundAmount] = useState<
    number | undefined
  >(undefined);
  const [createPartialRefund] = useMutation(REFUND_PAYMENT, {
    refetchQueries: [{ query: GET_BOOKINGS }],
  });

  const [resendGuarantee, { loading: resendGuaranteeLoading }] =
    useMutation(RESEND_GUARANTEE);

  const [createStripePayment, { loading: createStripePaymentLoading }] =
    useMutation(CREATE_STRIPE_PAYMENT);

  const { data } = useQuery(GET_PAYMENTTYPES, {});

  const getRefundedAmount = useCallback(() => {
    // let stripe: any;
    let amount = 0;
    let credit: any;
    let refund: any;
    if (payment && payment.stripe) {
      try {
        if (payment.stripe.credit)
          credit = JSON.parse(unescape(payment.stripe.credit));
        if (payment.stripe.refund)
          refund = JSON.parse(unescape(payment.stripe.refund));
      } catch (err) {
        console.log(err);
      }
    }

    if (refund) {
      amount = refund.amount / 100;
    }

    return amount;
  }, [payment]);

  const _onChangeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { target } = event;
    const { value, id, type } = target;

    // let stripe: any;
    let invoice: any;
    let credit: any;
    let refund: any;
    let intent: any;
    if (payment && payment.stripe) {
      try {
        if (payment.stripe.invoice)
          invoice = JSON.parse(unescape(payment.stripe.invoice));
        if (payment.stripe.credit)
          credit = JSON.parse(unescape(payment.stripe.credit));
        if (payment.stripe.refund)
          refund = JSON.parse(unescape(payment.stripe.refund));
        if (payment.stripe.intent)
          intent = JSON.parse(unescape(payment.stripe.intent));
      } catch (err) {
        console.log(err);
      }
    }

    handleChange(prevState => {
      let updatedPayment: IPayment | undefined = _.cloneDeep(prevState);

      if (updatedPayment) {
        updatedPayment = {
          ...updatedPayment,
          [id]: type === 'number' ? Number(value) : value,
        };
      }

      return updatedPayment;
    });
  };

  const _onChangeVat = (value: number) => {
    handleChange(prevState => {
      let updatedPayment: IPayment | undefined = _.cloneDeep(prevState);

      if (updatedPayment) {
        updatedPayment = {
          ...updatedPayment,
          vatPercentage: value,
        };
      }

      return updatedPayment;
    });
  };

  const _onChangeInputDate = (value: string) => {
    handleChange(prevState => {
      let updatedPayment: IPayment | undefined = _.cloneDeep(prevState);

      if (updatedPayment) {
        updatedPayment = {
          ...updatedPayment,
          dateDue: moment(value).toISOString(),
        };
      }

      return updatedPayment;
    });
  };

  const _onChangeInputSendInvoice = (checked: boolean) => {
    handleChange(prevState => {
      let updatedPayment: IPayment | undefined = _.cloneDeep(prevState);

      if (updatedPayment) {
        updatedPayment = {
          ...updatedPayment,
          auto: checked,
        };
      }

      return updatedPayment;
    });
  };

  const _onChangeInputAutoPayInvoice = (checked: boolean) => {
    handleChange(prevState => {
      let updatedPayment: IPayment | undefined = _.cloneDeep(prevState);

      if (updatedPayment) {
        updatedPayment = {
          ...updatedPayment,
          setPaidOnCreaction: checked,
        };
      }

      return updatedPayment;
    });
  };

  const _onChangeInputAddGuarantee = (checked: boolean) => {
    handleChange(prevState => {
      let updatedPayment: IPayment | undefined = _.cloneDeep(prevState);

      if (updatedPayment) {
        updatedPayment = {
          ...updatedPayment,
          includesGuarantee: checked,
        };
      }

      return updatedPayment;
    });
  };

  const _onChangeInputPaid = (checked: boolean) => {
    handleChange(prevState => {
      let updatedPayment: IPayment | undefined = _.cloneDeep(prevState);

      if (updatedPayment) {
        updatedPayment = {
          ...updatedPayment,
          isPaid: checked,
        };
      }

      return updatedPayment;
    });
  };

  const parsePaymentTypeOptions = (paymentTypes: IPaymentType[]) => {
    const parsedPaymentTypes: ComboboxItem[] = [];

    for (let i = 0; i < paymentTypes.length; i += 1) {
      const paymentType = paymentTypes[i];
      if (paymentType.id && paymentType.label)
        parsedPaymentTypes.push({
          id: paymentType.id,
          name: paymentType.label,
        });
    }

    return parsedPaymentTypes;
  };

  const setTempSelection = (paymentType: IPaymentType | undefined) => {
    handleChange(prevState => ({ ...prevState, type: paymentType }));
  };

  const _onChangeInputPartialRefund = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { target } = event;
    const { value } = target;

    setPartialRefundText(value);
  };

  const _onChangeInputPartialAmount = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { target } = event;
    const { value } = target;

    setPartialRefundAmount(parseFloat(value));
  };

  const getMollieRefundURL = (paymentData: IPayment) => {
    if (paymentData && paymentData.mollie && paymentData.mollie.intent) {
      const refund = JSON.parse(paymentData.mollie.intent);
      if (refund && refund._links && refund._links.dashboard) {
        return refund._links.dashboard.href;
      }
    }
    return undefined;
  };

  const getMolliePaymentURL = (paymentData: IPayment) => {
    if (paymentData && paymentData.mollie && paymentData.mollie.intent) {
      const intent = JSON.parse(paymentData.mollie.intent);
      if (intent && intent._links && intent._links.dashboard) {
        return intent._links.dashboard.href;
      }
    }
    return undefined;
  };

  return (
    <>
      <form className='space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0'>
        <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
          <div>
            <label
              htmlFor='amount'
              className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
            >
              {' '}
              Bedrag (incl BTW) *:{' '}
            </label>
          </div>
          <div className='sm:col-span-2'>
            <Input
              name='amount'
              id='amount'
              type='number'
              value={payment && payment.amount ? payment.amount : ''}
              onChange={_onChangeInput}
              disabled={!!(payment && (payment.invoiceId || payment.paymentId))}
            />
          </div>
        </div>
        {((payment && !payment.stripe) ||
          (payment && payment.stripe && payment?.includesGuarantee)) && (
          <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
            <div>
              <label
                htmlFor='dueDate'
                className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
              >
                {' '}
                <Switch
                  label='Waarborg'
                  // style={{ width: '2.5rem' }}
                  onChange={_onChangeInputAddGuarantee}
                  checked={!!(payment && payment.includesGuarantee)}
                  disabled={
                    !!(payment && (payment.invoiceId || payment.paymentId))
                  }
                />
              </label>
            </div>
            <div className='sm:col-span-2'>
              <Input
                name='guaranteeAmount'
                id='guaranteeAmount'
                value={
                  payment && payment.guaranteeAmount
                    ? payment.guaranteeAmount
                    : ''
                }
                onChange={_onChangeInput}
                type='number'
                disabled={
                  !!(payment && (payment.invoiceId || payment.paymentId)) ||
                  !payment?.includesGuarantee
                }
              />
            </div>
            <p className='mt-2 text-sm sm:col-span-3'>
              Deze waarborg zal als een aparte lijn de factuur worden toegevoegd
              met een BTW percentage van 0%. Let op! Wanneer de factuur het type
              `waarborg` heeft zal deze waarde genegeerd worden.
            </p>
          </div>
        )}
        {payment && payment.includesGuarantee && (
          <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
            <div>
              <label
                htmlFor='amount'
                className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
              >
                {' '}
                Bedrag inclusief waarborg (incl BTW) *:{' '}
              </label>
            </div>
            <div className='sm:col-span-2'>
              €{' '}
              {payment
                ? (payment.amount || 0) + (payment.guaranteeAmount || 0)
                : undefined}
            </div>
          </div>
        )}
        {payment && payment.refundId && (
          <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
            <div>
              <label
                htmlFor='amount'
                className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
              >
                {' '}
                Terugbetaald bedrag (incl BTW):{' '}
              </label>
            </div>
            <div className='sm:col-span-2'>€ {getRefundedAmount()}</div>
          </div>
        )}
        <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
          <div>
            <label
              htmlFor='percentage'
              className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
            >
              {' '}
              BTW Percentage *:{' '}
            </label>
          </div>
          <div className='sm:col-span-2'>
            <Select
              defaultValue={
                payment && payment.vatPercentage
                  ? payment.vatPercentage.toString()
                  : '0'
              }
              onChange={(e: any) => {
                e.preventDefault();
                _onChangeVat(parseInt(e.target.value, 10));
              }}
              disabled={!!(payment && (payment.invoiceId || payment.paymentId))}
            >
              <option value='0'>0 %</option>
              <option value='6'>6 %</option>
              <option value='21'>21 %</option>
            </Select>
          </div>
        </div>
        <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
          <div>
            <label
              htmlFor='dueDate'
              className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
            >
              {' '}
              Factuur versturen op:{' '}
            </label>
          </div>
          <div className='sm:col-span-2'>
            <Input
              name='dateDue'
              id='dateDue'
              type='date'
              value={
                payment && payment.dateDue
                  ? moment(payment.dateDue).format('YYYY-MM-DD')
                  : ''
              }
              onChange={e => {
                const { target } = e;
                const { value } = target;
                _onChangeInputDate(value);
              }}
              disabled={!!(payment && (payment.invoiceId || payment.paymentId))}
            />
          </div>
        </div>
        <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
          <div>
            <label
              htmlFor='type'
              className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
            >
              {' '}
              Type *:{' '}
            </label>
          </div>
          <div className='sm:col-span-2'>
            <Combobox
              items={
                data && data.findManyPaymentTypes
                  ? parsePaymentTypeOptions(data.findManyPaymentTypes)
                  : []
              }
              selected={
                payment && payment.type
                  ? { id: payment.type.id, name: payment.type.label! }
                  : undefined
              }
              setSelected={value => {
                let paymentType: IPaymentType | undefined;
                if (data && data.findManyPaymentTypes) {
                  for (
                    let i = 0;
                    i < data.findManyPaymentTypes.length;
                    i += 1
                  ) {
                    if (data.findManyPaymentTypes[i].id === value.id)
                      paymentType = data.findManyPaymentTypes[i];
                  }
                }
                setTempSelection(paymentType);
              }}
              placeholder='zoeken...'
              disabled={!!(payment && (payment.invoiceId || payment.paymentId))}
            />
          </div>
        </div>
        <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
          <div>
            <label
              htmlFor='dueDate'
              className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
            >
              {' '}
              Omschrijving:{' '}
            </label>
          </div>
          <div className='sm:col-span-2'>
            <Input
              name='title'
              id='title'
              value={payment && payment.title ? payment.title : ''}
              onChange={_onChangeInput}
              type='text'
              disabled={!!(payment && (payment.invoiceId || payment.paymentId))}
            />
          </div>
        </div>
        <div className='space-y-1 px-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
          {payment && !payment.stripe && (
            <Switch
              label='Factuur aanmaken'
              // style={{ width: '2.5rem' }}
              onChange={_onChangeInputSendInvoice}
              checked={!!(payment && payment.auto)}
              disabled={!!(payment && (payment.invoiceId || payment.paymentId))}
            />
          )}
          <Switch
            label={
              payment && (payment.invoiceId || payment.paymentId)
                ? 'Betaald (extern beheerd)'
                : 'Betaald'
            }
            // style={{ width: '2.5rem' }}
            onChange={_onChangeInputPaid}
            checked={!!(payment && payment.isPaid)}
            disabled={!!(payment && (payment.invoiceId || payment.paymentId))}
          />
        </div>
        {payment && payment.auto && payment.type?.id !== 3 && !payment.stripe && (
          <div className='space-y-1 px-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
            <Switch
              label='Factuur automatisch betalen bij aanmaak'
              // style={{ width: '2.5rem' }}
              onChange={_onChangeInputAutoPayInvoice}
              checked={!!(payment && payment.setPaidOnCreaction)}
              disabled={!!(payment && (payment.invoiceId || payment.paymentId))}
            />
            {payment.setPaidOnCreaction && (
              <label>
                De factuur zal bij aanmaken meteen als betaal worden
                weergegeven. Dit kan gebruikt worden om voor manuele betalingen
                in het verleden toch een factuur aan te maken. Deze factuur zal
                niet worden verstuurd aan de klant.
              </label>
            )}
          </div>
        )}

        {payment && payment.id && (
          <dl>
            <div className='bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>ID</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {payment.id}
              </dd>
            </div>
            {payment && payment.provider !== 'mollie' && (
              <>
                <div className='bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                  <dt className='text-sm font-medium text-gray-500'>
                    Stripe factuur
                  </dt>
                  <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                    {payment.invoiceId && (
                      <a
                        href={`https://dashboard.stripe.com/search?query=${payment.invoiceId}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <Button secondary size='sm'>
                          {payment.invoiceId}
                        </Button>
                      </a>
                    )}
                  </dd>
                </div>
                <div className='bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                  <dt className='text-sm font-medium text-gray-500'>
                    Stripe creditnota
                  </dt>
                  <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                    {payment.creditId && (
                      <a
                        href={`https://dashboard.stripe.com/search?query=${payment.creditId}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <Button secondary size='sm'>
                          {payment.creditId}
                        </Button>
                      </a>
                    )}
                  </dd>
                </div>
                <div className='bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                  <dt className='text-sm font-medium text-gray-500'>
                    Stripe refund
                  </dt>
                  <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                    {payment.refundId && (
                      <a
                        href={`https://dashboard.stripe.com/search?query=${payment.refundId}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <Button secondary size='sm'>
                          {payment.refundId}
                        </Button>
                      </a>
                    )}
                  </dd>
                </div>
                <div className='bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                  <dt className='text-sm font-medium text-gray-500'>
                    Stripe payment
                  </dt>
                  <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                    {payment.paymentId && (
                      <a
                        href={`https://dashboard.stripe.com/search?query=${payment.paymentId}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <Button secondary size='sm'>
                          {payment.paymentId}
                        </Button>
                      </a>
                    )}
                  </dd>
                </div>
              </>
            )}
            {payment && payment.provider === 'mollie' && (
              <>
                <div className='bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                  <dt className='text-sm font-medium text-gray-500'>Factuur</dt>
                  <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                    {payment.invoiceId && (
                      <a
                        href={`https://eenvoudigfactureren.be/invoices#pg=view&doc_id=${payment.invoiceId}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <Button secondary size='sm'>
                          {payment.invoiceId}
                        </Button>
                      </a>
                    )}
                  </dd>
                </div>
                <div className='bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                  <dt className='text-sm font-medium text-gray-500'>
                    Creditnota
                  </dt>
                  <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                    {payment.creditId && (
                      <a
                        href={`https://eenvoudigfactureren.be/invoices#pg=view&doc_id=${payment.creditId}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <Button secondary size='sm'>
                          {payment.creditId}
                        </Button>
                      </a>
                    )}
                  </dd>
                </div>
                <div className='bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                  <dt className='text-sm font-medium text-gray-500'>
                    Mollie refund
                  </dt>
                  <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                    {payment.refundId && (
                      <a
                        href={getMollieRefundURL(payment)}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <Button secondary size='sm'>
                          Bekijk refund
                        </Button>
                      </a>
                    )}
                  </dd>
                </div>
                <div className='bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                  <dt className='text-sm font-medium text-gray-500'>
                    Mollie payment
                  </dt>
                  <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                    {payment.mollie && payment.mollie.intent && (
                      <a
                        href={getMolliePaymentURL(payment)}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <Button secondary size='sm'>
                          Bekijk betaling
                        </Button>
                      </a>
                    )}
                  </dd>
                </div>
              </>
            )}
            <div className='bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Aangemaakt</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {moment(payment.createdAt).format('DD-MM-YYYY MM-HH-SS')}
              </dd>
            </div>
            <div className='bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>
                Laatst bijgewerkt
              </dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {moment(payment.updatedAt).format('DD-MM-YYYY MM-HH-SS')}
              </dd>
            </div>
          </dl>
        )}
        {payment &&
          payment.id &&
          payment.auto &&
          (!payment.stripe || !payment.stripe.invoice) &&
          (!payment.mollie || !payment.mollie.invoice) &&
          !payment.uuid && (
            <div className='space-y-1 px-4 sm:space-y-0 sm:px-6 sm:py-5'>
              <Button
                primary
                style={{ marginBottom: 2, width: 'auto' }}
                className='text-base'
                onClick={e => {
                  e.preventDefault();
                  createStripePayment({
                    variables: {
                      where: {
                        id: payment.id,
                      },
                    },
                  });
                }}
              >
                Betaling nu aanmaken
                {createStripePaymentLoading && (
                  <svg
                    className='animate-spin ml-2 mr-3 h-6 w-6 text-red'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    />
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                    />
                  </svg>
                )}
              </Button>
            </div>
          )}
        {payment && payment.id && payment.type && payment.type.id === 3 && (
          <div className='space-y-1 px-4 sm:space-y-0 sm:px-6 sm:py-5'>
            <Button
              primary
              style={{ marginBottom: 2, width: 'auto' }}
              className='text-base'
              onClick={e => {
                e.preventDefault();
                resendGuarantee({
                  variables: {
                    where: {
                      id: payment.id,
                    },
                  },
                });
              }}
            >
              Waarborg email opnieuw verzenden
              {resendGuaranteeLoading && (
                <svg
                  className='animate-spin ml-2 mr-3 h-6 w-6 text-red'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  />
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  />
                </svg>
              )}
            </Button>
          </div>
        )}
      </form>
      <Dialog
        title='Betaling verwijderen'
        setOpen={setOpenDeletePaymentDialog}
        open={isOpenDeletePaymentDialog}
        icon={
          <ExclamationIcon
            className='h-6 w-6 text-red-600'
            aria-hidden='true'
          />
        }
        description={`Je staat op het punt deze betaling met id ${
          payment!.id
        } te verwijderen. Weet je zeker dat je deze betaling wil verwijderen?`}
        actions={
          <div className='space-x-3'>
            <Button
              secondary
              style={{ marginBottom: 2 }}
              disabled={!payment || !payment.id}
              onClick={e => {
                e.preventDefault();
                deletePayment({
                  variables: {
                    data: {
                      where: {
                        id: payment!.id,
                      },
                    },
                  },
                }).then(() => {
                  setOpenRefundDialog(false);
                  setOpenPayment(false);
                });
              }}
            >
              Verwijderen
            </Button>
            <Button
              primary
              style={{ marginBottom: 2 }}
              onClick={e => {
                e.preventDefault();
                setOpenDeletePaymentDialog(false);
              }}
            >
              Annuleren
            </Button>
          </div>
        }
        lightDismiss
      />
      <Dialog
        title='Terugstorten'
        setOpen={setOpenRefundDialog}
        open={isOpenRefundDialog}
        icon={
          <ExclamationIcon
            className='h-6 w-6 text-red-600'
            aria-hidden='true'
          />
        }
        description='Je staat op het punt deze betaling, of een deel van deze betaling, terug te storten.'
        actions={
          <div className='space-x-3'>
            <Button
              secondary
              style={{ marginBottom: 2 }}
              disabled={
                !partialRefundText ||
                !partialRefundAmount ||
                partialRefundAmount === 0
              }
              onClick={e => {
                e.preventDefault();
                createPartialRefund({
                  variables: {
                    data: {
                      paymentID: payment?.id,
                      description: partialRefundText,
                      amount: partialRefundAmount,
                    },
                  },
                }).then(() => {
                  setOpenRefundDialog(false);
                  setOpenPayment(false);
                });
                // dismissPanel();
              }}
            >
              Terugbetalen
            </Button>
            <Button
              primary
              style={{ marginBottom: 2 }}
              onClick={e => {
                e.preventDefault();
                setOpenRefundDialog(false);
              }}
            >
              Annuleren
            </Button>
          </div>
        }
        lightDismiss
      >
        <div className='gap-1 flex flex-col'>
          <label className='text-sm'>Vermelding</label>
          <Input
            name='partialRefundText'
            id='partialRefundText'
            value={partialRefundText}
            onChange={_onChangeInputPartialRefund}
            type='text'
          />
        </div>
        <div className='gap-1 flex flex-col mt-2'>
          <label className='text-sm'>Bedrag</label>
          <Input
            name='partialRefundAmount'
            id='partialRefundAmount'
            value={partialRefundAmount}
            onChange={_onChangeInputPartialAmount}
            type='number'
          />
          <div className='text-sm flex gap-1'>
            {payment &&
              ((payment.type && payment.type.id === 3) ||
                payment.includesGuarantee) && (
                <button
                  onClick={e => {
                    e.preventDefault();
                    if (
                      payment &&
                      payment.type &&
                      payment.type.id === 3 &&
                      payment.amount
                    ) {
                      setPartialRefundAmount(payment.amount);
                    } else if (
                      payment &&
                      payment.includesGuarantee &&
                      payment.guaranteeAmount
                    ) {
                      setPartialRefundAmount(payment.guaranteeAmount);
                    }
                  }}
                  type='button'
                  className='underline hover:no-underline'
                >
                  Neem waarborg over
                </button>
              )}
            <button
              onClick={e => {
                e.preventDefault();
                if (payment && payment.includesGuarantee) {
                  setPartialRefundAmount(
                    (payment.amount || 0) + (payment.guaranteeAmount || 0),
                  );
                } else if (payment) {
                  setPartialRefundAmount(payment.amount || 0);
                }
              }}
              type='button'
              className='underline hover:no-underline'
            >
              Neem het volledige bedrag over
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export const Bookings: React.FC = ({ ...props }) => {
  const [isOpen, setOpen] = useState(false);

  const [selection, setSelection] = useState<IBooking | undefined>({});
  const [units, setUnits] = useState<IUnit[]>([]);
  const [isOpenPayment, setOpenPayment] = useState(false);
  const [isOpenRefundDialog, setOpenRefundDialog] = useState(false);

  const [isOpenDeletePaymentDialog, setOpenDeletePaymentDialog] =
    useState(false);

  const [dialogCancelOpen, setDialogCancelOpen] = useState(false);
  const [error, setError] = useState<any>();
  // const [bookings, setBookings] = useState<IBooking[]>([]);
  const [filter, setFilter] = useState<string>('');

  const [paymentSelection, setPaymentSelection] = useState<IPayment>({});

  const [sorting, setSorting] = useState<{ key: string; direction: string }>({
    key: 'start',
    direction: 'asc',
  });
  const [debouncedFilter, setDebouncedFilter] = useState<string>('');

  const [bulkSelection, setBulkSelection] = useState<number[]>([]);

  useDebounce(filter, 500, (value: any) => {
    setDebouncedFilter(value);
  });

  interface IFilters {
    start?: Date;
    end?: Date;
  }
  const [filters, setFilters] = useState<IFilters>({
    start: moment().subtract(1, 'week').toDate(),
  });

  useEffect(() => {
    if (filter && filter.length > 0) {
      setLazyLoadingState({
        skip: 0,
        take: 20,
        lastCall: undefined,
      });
      setFilters({});
    }
  }, [filter]);

  useEffect(() => {
    setLazyLoadingState({
      skip: 0,
      take: 20,
      lastCall: undefined,
    });
  }, [filters]);

  const getFilterObject = (
    inputFilters: any,
    inputDebouncedFilter: any,
    selectedUnits?: number[],
  ) => {
    const tempFilterObject: any = {
      AND: [
        {
          OR: [
            {
              unit: {
                title: { contains: inputDebouncedFilter },
              },
            },
            {
              user: {
                OR: [
                  { email: { contains: inputDebouncedFilter } },
                  {
                    profile: {
                      OR: [
                        { firstName: { contains: inputDebouncedFilter } },
                        { lastName: { contains: inputDebouncedFilter } },
                      ],
                    },
                  },
                ],
              },
            },
            {
              remarks: {
                contains: inputDebouncedFilter,
              },
            },
          ],
        },
      ],
    };

    if (inputFilters.start) {
      tempFilterObject.AND = [
        ...tempFilterObject.AND,
        { start: { gte: moment(inputFilters.start).toISOString() } },
      ];
    }

    if (inputFilters.end) {
      tempFilterObject.AND = [
        ...tempFilterObject.AND,
        { end: { lte: moment(inputFilters.end).toISOString() } },
      ];
    }

    const currentSelection = selectedUnits;

    if (currentSelection && currentSelection.length > 0) {
      const unitArray = [];
      for (let i = 0; i < currentSelection.length; i += 1) {
        unitArray.push(currentSelection[i]);
      }

      tempFilterObject.AND = [
        ...tempFilterObject.AND,
        { unit: { id: { in: unitArray } } },
      ];
    }

    return tempFilterObject;
  };

  const [filterObject, setFilterObject] = useState<any>(
    getFilterObject(filters, debouncedFilter),
  );

  const [unitSelection, setUnitSelection] = useState<number[]>([]);

  const [isOpenDialogBulkDelete, setOpenDialogBulkDelete] = useState(false);

  useEffect(() => {
    const tempFilterObject = getFilterObject(
      filters,
      debouncedFilter,
      unitSelection,
    );

    client.cache.reset();
    setFilterObject(tempFilterObject);
  }, [filters, debouncedFilter, unitSelection]);

  const [lazyLoadingState, setLazyLoadingState] = useState({
    skip: 0,
    take: 20,
    lastCall: undefined,
  });

  const {
    data: bookings,
    loading: loadingBookings,
    fetchMore,
    client,
  } = useQuery(GET_BOOKINGS, {
    variables: {
      orderBy: { [sorting.key]: sorting.direction },
      where: filterObject,
      skip: 0,
      take: 20,
    },
    // fetchPolicy: 'no-cache',
    // pollInterval: 60 * 1000,
    notifyOnNetworkStatusChange: true,
    onCompleted: (x: any) => {
      if (x && x.findManyBookings) {
        setLazyLoadingState(prevState => ({
          ...prevState,
          skip: prevState.skip + prevState.take,
          lastCall: x.findManyBookings.length,
        }));
        // setBookings(x.findManyBookings);
        if (selection && selection.id) {
          for (let i = 0; i < x.findManyBookings.length; i += 1) {
            // console.log(x.findManyBookings[i].id);
            if (
              x.findManyBookings[i] &&
              x.findManyBookings[i].id === selection.id
            ) {
              setTimeout(() => {
                setSelection(x.findManyBookings[i]);
              }, 100);
            }
          }
        }
      }
    },
  });

  const fetchMoreBookings = () => {
    fetchMore({
      variables: {
        orderBy: sorting ? { [sorting.key]: sorting.direction } : undefined,
        where: filterObject,
        skip: lazyLoadingState.skip,
        take: lazyLoadingState.take,
      },
    });
  };

  const { data: dataUnits } = useQuery(GET_UNITS, {
    variables: {
      query: {
        where: {
          dummy: {
            equals: false,
          },
        },
      },
    },
  });

  useEffect(() => {
    if (dataUnits && dataUnits.findManyUnits) {
      setUnits(dataUnits.findManyUnits);
    }
  }, [dataUnits]);

  const onColumnClick = (
    ev: React.MouseEvent<HTMLElement, MouseEvent>,
    column?: IColumn,
  ): void => {
    if (column && column.fieldName) {
      client.cache.reset();
      setSorting({
        key: column.fieldName,
        direction: column.isSortedDescending ? 'asc' : 'desc',
      });
    }
  };

  const editItem = (item: IBooking) => {
    setSelection(item);
    setOpen(true);
  };

  const columns: IColumn[] = [
    {
      key: 'column1',
      name: 'Aangemaakt',
      fieldName: 'createdAt',
      render: (item: IBooking) => {
        return moment(item.createdAt).format('DD-MM-YYYY');
      },
      onHeaderClick: onColumnClick,
      sortable: true,
      isSorted: sorting && sorting.key === 'createdAt',
      isSortedDescending: sorting && sorting.direction === 'desc',
    },
    {
      key: 'column2',
      name: 'email',
      fieldName: 'user.email',
      render: (item: IBooking) => {
        return (
          <span className='text-ellipsis overflow-hidden block w-60'>
            {item.user && item.user.profile && (
              <span>
                {item.user.profile.firstName} {item.user.profile.lastName}
              </span>
            )}
            {' - '}
            {item.user && item.user.email}
          </span>
        );
      },
    },
    {
      key: 'column3',
      name: 'Unit',
      fieldName: 'unit.title',
      render: (item: IBooking) => {
        return (
          <span className='text-ellipsis overflow-hidden block w-20'>
            {item.unit && item.unit.title}
          </span>
        );
      },
    },
    {
      key: 'column4 ',
      name: 'Aankomst',
      fieldName: 'start',
      render: (item: IBooking) => {
        return <span>{moment(item.start).format('DD-MM-YYYY')}</span>;
      },
      onHeaderClick: onColumnClick,
      sortable: true,
      isSorted: sorting && sorting.key === 'start',
      isSortedDescending: sorting && sorting.direction === 'desc',
    },
    {
      key: 'column5',
      name: 'Vertrek',
      fieldName: 'end',
      onHeaderClick: onColumnClick,
      sortable: true,
      render: (item: IBooking) => {
        return <span>{moment(item.end).format('DD-MM-YYYY')}</span>;
      },
      isSorted: sorting && sorting.key === 'end',
      isSortedDescending: sorting && sorting.direction === 'desc',
    },
    {
      key: 'column6',
      name: 'Prijs',
      fieldName: 'price',
      render: (item: IBooking) => {
        return <span>€{item.price}</span>;
      },
      onHeaderClick: onColumnClick,
      sortable: true,
      isSorted: sorting && sorting.key === 'price',
      isSortedDescending: sorting && sorting.direction === 'desc',
    },
    {
      key: 'column7',
      name: 'Status',
      fieldName: 'status',
      render: (item: IBooking) => {
        return <Status status={item ? getBookingStatus(item) : 'open'} />;
      },
    },
    {
      key: 'column8',
      name: '',
      // fieldName: 'status',
      render: (item: IBooking) => {
        let externalSource = '';
        if (
          item.externalBooking &&
          item.externalBooking.source &&
          item.externalBooking.source.title
        ) {
          externalSource = item.externalBooking.source.title;
        } else if (item.externalBooking) {
          externalSource = 'extern';
        }
        return externalSource ? (
          <span
            className='px-2 py-1 rounded-lg inline-block bg-gray-200 text-ellipsis overflow-hidden block'
            style={{ maxWidth: '120px' }}
          >
            {externalSource}
          </span>
        ) : (
          ''
        );
      },
    },
    {
      key: 'column6',
      name: '',
      render: (item: IBooking) => {
        return (
          <button
            className='text-indigo-600 hover:text-indigo-900'
            type='button'
            onClick={() => editItem(item)}
          >
            bewerken<span className='sr-only'>, {item.title}</span>
          </button>
        );
      },
    },
  ];

  const [createBooking, { loading: creatingBooking }] = useMutation(
    CREATE_BOOKING,
    {
      refetchQueries: [{ query: GET_BOOKINGS }],
    },
  );

  const [updateBooking, { loading: updatingBooking }] = useMutation(
    UPDATE_BOOKING,
    {
      refetchQueries: [{ query: GET_BOOKINGS }],
    },
  );

  const [cancelBooking] = useMutation(CANCEL_BOOKING, {
    onCompleted: d => {
      if (d && d.cancelBooking) {
        setSelection(d.cancelBookng);
      }

      setDialogCancelOpen(false);
      setOpen(false);

      // refetch();
    },
    refetchQueries: [{ query: GET_BOOKINGS }],
  });

  const [deleteBooking] = useMutation(DELETE_BOOKING, {
    refetchQueries: [{ query: GET_BOOKINGS }],
  });

  const [deleteBookings] = useMutation(DELETE_BOOKINGS, {
    refetchQueries: [{ query: GET_BOOKINGS }],
  });

  const [deletePayment] = useMutation(DELETE_PAYMENT, {
    refetchQueries: [{ query: GET_BOOKINGS }],
  });

  const [createPayment] = useMutation(CREATE_PAYMENT, {
    refetchQueries: [{ query: GET_BOOKINGS }],
  });

  const [updatePayment] = useMutation(UPDATE_PAYMENT, {
    // notifyOnNetworkStatusChange: true,
    refetchQueries: [{ query: GET_BOOKINGS }],
  });

  const savePayment = React.useCallback(() => {
    if (paymentSelection && paymentSelection.id && selection) {
      const paymentArgs = {
        variables: {
          where: {
            id: paymentSelection.id,
          },
          data: {
            type: {
              connect: {
                id: paymentSelection.type
                  ? paymentSelection.type.id
                  : undefined,
              },
            },
            amount: paymentSelection.amount,
            vatPercentage: paymentSelection.vatPercentage,
            dateDue: paymentSelection.dateDue,
            booking: {
              connect: {
                id: selection.id,
              },
            },
            auto: paymentSelection.auto,
            setPaidOnCreaction: paymentSelection.setPaidOnCreaction,
            title: paymentSelection.title,
            business: paymentSelection.business,
            isPaid: paymentSelection.isPaid,
            includesGuarantee: paymentSelection.includesGuarantee,
            guaranteeAmount: paymentSelection.guaranteeAmount,
          },
        },
      };

      updatePayment({
        ...paymentArgs,
      })
        .then(() => {
          setOpenPayment(false);
          setPaymentSelection({});
        })
        .catch(err => {
          setError(err);
        });
    } else if (selection) {
      const paymentArgs = {
        variables: {
          data: {
            type: {
              connect: {
                id: paymentSelection.type
                  ? paymentSelection.type.id
                  : undefined,
              },
            },
            amount: paymentSelection.amount,
            vatPercentage: paymentSelection.vatPercentage,
            dateDue: paymentSelection.dateDue,
            booking: {
              connect: {
                id: selection.id,
              },
            },
            auto: paymentSelection.auto,
            title: paymentSelection.title,
            business: paymentSelection.business,
            isPaid: paymentSelection.isPaid,
            includesGuarantee: paymentSelection.includesGuarantee,
            guaranteeAmount: paymentSelection.guaranteeAmount,
          },
        },
      };

      createPayment({
        ...paymentArgs,
      })
        .then(() => {
          setOpenPayment(false);
          setPaymentSelection({});
        })
        .catch(err => {
          setError(err);
        });
    }
  }, [paymentSelection, createPayment, updatePayment, selection]);

  const save = React.useCallback(() => {
    if (selection) {
      let profileData: any;

      if (selection && selection.user && selection.user.profile) {
        profileData = {
          firstName:
            selection.user.profile.firstName &&
            selection.user.profile.firstName.length > 0
              ? selection.user.profile.firstName
              : undefined,
          lastName:
            selection.user.profile.lastName &&
            selection.user.profile.lastName.length > 0
              ? selection.user.profile.lastName
              : undefined,
          vat:
            selection.user.profile.vat && selection.user.profile.vat.length > 0
              ? selection.user.profile.vat
              : undefined,
          company:
            selection.user.profile.company &&
            selection.user.profile.company.length > 0
              ? selection.user.profile.company
              : undefined,
          phone:
            selection.user.profile.phone &&
            selection.user.profile.phone.length > 0
              ? selection.user.profile.phone
              : undefined,
          mobile:
            selection.user.profile.mobile &&
            selection.user.profile.mobile.length > 0
              ? selection.user.profile.mobile
              : undefined,
        };
      }

      if (
        selection &&
        selection.user &&
        selection.user.profile &&
        selection.user.profile.id
      ) {
        // profile is updated
        if (selection.user.profile.address) {
          if (selection.user.profile.address.id) {
            // update address
            profileData.address = {
              update: {
                street: selection.user.profile.address.street,
                postcode: selection.user.profile.address.postcode,
                city: selection.user.profile.address.city,
                country: selection.user.profile.address.country,
              },
            };
          } else {
            // create address
            profileData.address = {
              create: {
                street: selection.user.profile.address.street,
                postcode: selection.user.profile.address.postcode,
                city: selection.user.profile.address.city,
                country: selection.user.profile.address.country,
              },
            };
          }
        }
      } else if (
        selection &&
        selection.user &&
        selection.user.profile &&
        selection.user.profile.address
      ) {
        // create address since profile does not exist
        profileData.address = {
          create: {
            street: selection.user.profile.address.street,
            postcode: selection.user.profile.address.postcode,
            city: selection.user.profile.address.city,
            country: selection.user.profile.address.country,
          },
        };
      }

      let user: any;

      if (selection.user && selection.user.id) {
        user = {
          update: {
            // id: selection.user.id,
          },
        };
      } else if (selection.user) {
        user = {
          create: {
            // id: selection.user.id,
          },
        };
      }

      if (
        selection.user &&
        selection.user.profile &&
        selection.user.profile.id &&
        user.update
      ) {
        user.update = {
          ...user.update,
          email: selection.user.email,
          profile: {
            update: profileData,
          },
        };
      } else if (selection.user && user.update && selection.user.profile) {
        user.update = {
          ...user.update,
          email: selection.user.email,
          profile: {
            create: profileData,
          },
        };
      } else if (selection.user && selection.user.profile) {
        user = {
          create: {
            email:
              selection.user.email && selection.user.email.length > 0
                ? selection.user.email
                : `dummyuser${moment().unix()}@bookingdashboard.be`,
            profile: {
              create: profileData,
            },
          },
        };
      }

      const options: any = {
        create: [],
        update: [],
      };

      if (selection.options) {
        for (let i = 0; i < selection.options.length; i += 1) {
          const option = selection.options[i];
          const optiondata = {
            fee: option.fee,
            amount: option.amount,
            price: option.price,
            name: option.name,
            option:
              option.id && option.id < 1
                ? {
                    connect: { id: option.option?.id },
                  }
                : undefined,
          };

          if (option.id && option.id < 0) {
            options.create.push(optiondata);
          } else if (option.id) {
            options.update.push({
              where: {
                id: option.id,
              },
              data: optiondata,
            });
          }
        }
      }

      if (selection.id) {
        updateBooking({
          variables: {
            data: {
              start: selection.start,
              end: selection.end,
              persons: selection.persons,
              price: selection.price,
              user,
              adults: selection.adults,
              children: selection.children,
              remarks: selection.remarks,
              createPayments: selection.sendPayment,
              options,
            },
            where: {
              id: selection.id,
            },
          },
        })
          .then((d: any) => {
            // console.log(data);
            // setSelection(prevState => data && data.updateBooking ? data.updateBokung : ...prevState)
            // dismissPanel();
            // refetch();
            setSelection(undefined);
            setOpen(false);
          })
          .catch(err => {
            setError(err);
          });
      } else {
        if (selection.user) {
          user = {
            create: {},
          };

          if (selection.user && selection.user.profile) {
            user.create = {
              ...user.create,
              email:
                selection.user.email && selection.user.email.length > 0
                  ? selection.user.email
                  : `dummyuser${moment().unix()}@bookingdashboard.be`,
              profile: {
                create: profileData,
              },
            };
          }
        }

        if (selection.period && selection.unit) {
          createBooking({
            variables: {
              data: {
                user,
                period: {
                  connect: {
                    id: selection.period.id,
                  },
                },
                unit: {
                  connect: {
                    id: selection.unit.id,
                  },
                },
                start: selection.start,
                persons: selection.persons,
                adults: selection.adults,
                children: selection.children,
                createPayments: selection.sendPayment,
                remarks: selection.remarks,
                options: {
                  create: options.create,
                },
              },
            },
          })
            .then(d => {
              setSelection(undefined);
              setOpen(false);
            })
            .catch(err => {
              setError(err);
              // dismissPanel();
            });
        }
      }
    }

    // setSelection({});
  }, [selection, createBooking, updateBooking]);

  const paymentFormComplete = React.useCallback(
    (payment: IPayment | undefined) => {
      if (!payment) return false;

      let valid = true;
      if (!payment.amount) valid = false;
      if (!payment.vatPercentage && payment.vatPercentage !== 0) valid = false;
      if (!payment.type) valid = false;

      return valid;
    },
    [],
  );

  const formComplete = React.useCallback((booking: IBooking | undefined) => {
    if (!booking) return false;

    let valid = true;
    if (!booking.unit && !booking.id) valid = false;
    if (!booking.period && !booking.id) valid = false;
    if (!booking.start && !booking.id) valid = false;
    if (!booking.user) valid = false;
    if (!booking.persons && !booking.id) valid = false;
    if (
      booking.user &&
      booking.user.email &&
      !ValidateEmail(booking.user.email)
    )
      valid = false;
    if (
      booking.user &&
      (!booking.user.profile ||
        !booking.user.profile.firstName ||
        booking.user.profile.firstName.length < 2 ||
        !booking.user.profile.lastName ||
        booking.user.profile.lastName.length < 2)
    )
      valid = false;

    return valid;
  }, []);

  const onRenderFooterContent = React.useCallback(
    booking => (
      <div className='flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6'>
        <div className='flex justify-between'>
          <div>
            {booking && booking.status !== 'canceled' && (
              <Button
                secondary
                size='lg'
                onClick={() => {
                  setDialogCancelOpen(true);
                }}
              >
                Boeking annuleren
              </Button>
            )}
          </div>
          <div className='space-x-3'>
            <Button
              secondary
              size='lg'
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              primary
              size='lg'
              onClick={() => {
                save();
              }}
              className='mr-1'
              disabled={!formComplete(selection)}
              // loading={creatingBooking || updatingBooking}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    ),
    [
      setOpen,
      formComplete,
      selection,
      setDialogCancelOpen,
      save,
      creatingBooking,
      updatingBooking,
    ],
  );

  const onRenderPaymentFooterContent = React.useCallback(
    () => (
      <div className='flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6 space-x-3 flex justify-between'>
        <div>
          <Button
            // variant='primary'
            primary
            size='lg'
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              setOpenRefundDialog(true);
            }}
            style={{ marginRight: 2 }}
            disabled={
              paymentSelection.isRefunded ||
              (!paymentSelection.stripe && !paymentSelection.mollie) ||
              (paymentSelection.stripe &&
                !paymentSelection.stripe.invoice &&
                !paymentSelection.stripe.intent) ||
              (paymentSelection.mollie && !paymentSelection.paymentId)
            }
          >
            Terugbetalen
          </Button>
        </div>
        <div className='flex gap-3'>
          <Button
            // variant='primary'
            secondary
            size='lg'
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              setOpenDeletePaymentDialog(true);
            }}
            style={{ marginRight: 2, display: 'none' }}
            disabled={!!paymentSelection.stripe}
          >
            Verwijderen
          </Button>
          <div className='flex gap-3'>
            <Button
              // variant='primary'
              secondary
              size='lg'
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                savePayment();
              }}
              style={{ marginRight: 2 }}
              disabled={!paymentFormComplete(paymentSelection)}
            >
              Opslaan
            </Button>
            <Button
              primary
              size='lg'
              // variant='secondary'
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                setOpenPayment(false);
              }}
            >
              Annuleren
            </Button>
          </div>
        </div>
      </div>
    ),
    [setOpenPayment, paymentFormComplete, paymentSelection, savePayment],
  );

  const pages: INavItem[] = [
    { name: 'Dashboard', href: '/' },
    { name: 'Boekingen', href: '/boekingen' },
  ];

  const navigation = [
    {
      name: 'Nieuw',
      onClick: () => {
        setSelection(undefined);
        setOpen(true);
      },
      current: false,
      // disabled: !!(selection && selection.id),
    },
    /* {
      name: 'Bewerken',
      onClick: () => {
        openPanel();
      },
      current: false,
      icon: <FontAwesomeIcon icon={faPen} />,
      disabled: !selection || !selection.id,
    },
    {
      name: 'Verwijderen',
      onClick: () => {
        openPanel();
      },
      current: false,
      icon: <FontAwesomeIcon icon={faTrash} />,
      disabled: !selection || !selection.id,
    }, */
  ];

  const filterActive = useCallback(
    (unit: IUnit) => {
      // console.log(unit.id, unitSelection.includes(unit.id || -1));
      if (unitSelection.includes(unit.id!)) return true;
      return false;
    },
    [unitSelection],
  );

  const getFilters = useCallback(() => {
    const newFilterObject: IFilter = {
      id: 'unit',
      name: 'Units',
      options: [],
    };

    units.map(item => {
      newFilterObject.options.push({
        value: item.id!,
        label: item.title!,
        checked: filterActive(item),
      });
      return true;
    });

    return newFilterObject;
  }, [units, filterActive]);

  return (
    <div {...props}>
      <Dialog
        title='Bulk boekingen verwijderen'
        setOpen={setOpenDialogBulkDelete}
        open={isOpenDialogBulkDelete}
        icon={
          <ExclamationIcon
            className='h-6 w-6 text-red-600'
            aria-hidden='true'
          />
        }
        description={`Je staat op het punt om ${bulkSelection.length} boekingen te verwijderen. Deze actie kan niet ongedaan worden gemaakt.`}  
        actions={
          <div className='space-x-3'>
            <Button
              secondary
              style={{ marginBottom: 2 }}
              disabled={bulkSelection.length < 1}
              onClick={e => {
                e.preventDefault();
                deleteBookings({
                  variables: {
                    
                      where: {
                        id: {
                          in: bulkSelection,
                        }
                      }
                    
                  }
                }).then(() => {
                  setOpenDialogBulkDelete(false);
                  setBulkSelection([]);
                })
                // deletePayment({
                //   variables: {
                //     data: {
                //       where: {
                //         id: payment!.id,
                //       },
                //     },
                //   },
                // }).then(() => {
                //   setOpenRefundDialog(false);
                //   setOpenPayment(false);
                // });
              }}
            >
              Verwijderen
            </Button>
            <Button
              primary
              style={{ marginBottom: 2 }}
              onClick={e => {
                e.preventDefault();
                setOpenDialogBulkDelete(false);
              }}
            >
              Annuleren
            </Button>
          </div>
        }
        lightDismiss
      />
      <PageHeader
        title='Boekingen beheren'
        pages={pages}
        home={{ href: '/' }}
        // loading={loadingBookings}
      >
        <div className='flex align-middle'>
          {navigation.map((item, index) => {
            return (
              <Button
                size='lg'
                primary
                onClick={item.onClick}
                // disabled={item.disabled}
                className={index > 0 ? 'ml-2' : ''}
                key={`nav_${index}_${item.name}`}
              >
                {item.name}
              </Button>
            );
          })}
          <div className='relative ml-5'>
            <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
              <SearchIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </div>
            <input
              id='search'
              name='search'
              className='block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-redo-500 focus:border-redo-500 sm:text-sm'
              placeholder='Search'
              type='search'
              autoComplete='off'
              onChange={e => {
                const { target } = e;
                const { value } = target;
                setFilter(value);
              }}
            />
          </div>
        </div>
      </PageHeader>
      <Filters
        filters={units && units.length > 0 ? [getFilters()] : []}
        selectedFilters={unitSelection || []}
        addFilter={(id: number) =>
          setUnitSelection(prevState => [...prevState, id])
        }
        removeFilter={(id: number) => {
          setUnitSelection(prevState => {
            const currentSelection = prevState;
            const newSelection: number[] = [];

            currentSelection.forEach(item => {
              if (item !== id) newSelection.push(item);
            });

            return newSelection;
          });
        }}
      >
        <div className='flex items-center space-x-2'>
          <label>Start</label>
          <Input
            type='date'
            value={
              filters.start ? moment(filters.start).format('YYYY-MM-DD') : ''
            }
            onChange={e => {
              const { target } = e;
              const { value } = target;

              setFilters(prevState => ({
                ...prevState,
                start: value ? moment(value).toDate() : undefined,
              }));
            }}
          />
        </div>
        <div className='flex items-center space-x-2'>
          <label>Einde</label>
          <Input
            type='date'
            value={filters.end ? moment(filters.end).format('YYYY-MM-DD') : ''}
            onChange={e => {
              const { target } = e;
              const { value } = target;

              setFilters(prevState => ({
                ...prevState,
                end: value ? moment(value).toDate() : undefined,
              }));
            }}
          />
        </div>
      </Filters>
      {bulkSelection && bulkSelection.length > 0 && (
        <div style={{ marginTop: "10px", display: "flex", gap: "10px" }}>
          {bulkSelection.length} items geselecteerd.
          <Button
            onClick={() => {
              setOpenDialogBulkDelete(true);
            }}
            primary
          >
            Delete selectie
          </Button>
        </div>
      )}
      {/* <Container>
        <Row>
          <Column
            colsm={12}
            colmd={3}
            collg={3}
          // style={{ flexDirection: ['row', 'column'] }}
          >
            <h1 style={{ marginBottom: 2 }}>Boekingen</h1>
            <Button
              // variant='sideMenuItem'
              onClick={() => {
                setSelection({});
                // openPanel();
              }}
            >
              Nieuw <FontAwesomeIcon icon={faPlus} />
            </Button>
            <Button
              // variant='sideMenuItem'
              style={{ marginTop: '1px', marginLeft: '10px' }}
              disabled={!selection}
              onClick={() => {
                // openPanel();
              }}
            >
              Bewerken <FontAwesomeIcon icon={faPen} />
            </Button>
            <Button
              // variant='sideMenuItem'
              style={{ marginTop: '10px', marginLeft: '10px', display: 'none' }}
              disabled={!selection || !selection.id}
              onClick={() => {
                openDialog();
              }}
            >
              Verwijderen <FontAwesomeIcon icon={faTrash} />
            </Button>
            <h3>Filters</h3>
            <div>
              <label>Begindatum</label>
              <DatePicker
                firstDayOfWeek={DayOfWeek.Monday}
                placeholder='Selecteer een datum...'
                // arialabel='Selecteer een datum'
                // DatePicker uses English strings by default. For localized apps, you must override this prop.
                strings={defaultDatePickerStrings}
                value={filters.start}
                onSelectDate={date => {
                  if (date)
                    setFilters(prevState => ({ ...prevState, start: date }));
                }}
                formatDate={date => {
                  return moment(date).format('DD/MM/YYYY');
                }}
              />
            </div>
            <div style={{ marginTop: 2 }}>
              <label>Einddatum</label>
              <DatePicker
                firstDayOfWeek={DayOfWeek.Monday}
                placeholder='Selecteer een datum...'
                // arialabel='Selecteer een datum'
                // DatePicker uses English strings by default. For localized apps, you must override this prop.
                strings={defaultDatePickerStrings}
                value={filters.end}
                onSelectDate={date => {
                  if (date)
                    setFilters(prevState => ({ ...prevState, end: date }));
                }}
                formatDate={date => {
                  return moment(date).format('DD/MM/YYYY');
                }}
              />
            </div>
            {units && units.length > 0 && (
              <div style={{ marginTop: 2 }}>
                <label>Units</label>

                <SelectionZone
                  // @ts-ignore
                  selection={_selection}
                  selectionMode={SelectionMode.multiple}
                  selectionPreservedOnEmptyClick
                >
                  {units.map((item: IUnit, index: number) => {
                    const isSelected =
                      unitSelection &&
                      unitSelection.length > 0 &&
                      unitSelection.filter(
                        filterItem => filterItem.id === item.id,
                      ).length > 0;

                    return (
                      <div
                        className={classNames.item}
                        data-is-focusable
                        data-selection-index={index}
                      >
                        <div
                          className={classNames.check}
                          data-is-focusable
                          data-selection-toggle
                        >
                          <Check checked={isSelected} />
                        </div>

                        <span className={classNames.cell}>{item.title}</span>
                      </div>
                    );
                  })}
                </SelectionZone>
              </div>
            )}
          </Column>
        </Row>
      </Container>
                */}
      <Table
        items={bookings ? bookings.findManyBookings : []}
        columns={columns}
        lazyLoading
        loadMore={
          lazyLoadingState.lastCall
            ? lazyLoadingState.lastCall % lazyLoadingState.take === 0
            : true
        }
        loading={loadingBookings}
        loadMoreCallback={() => {
          fetchMoreBookings();
        }}
        allowSelection
        selectionState={bulkSelection}
        setSelectionState={setBulkSelection}
      />

      <Notification
        show={!!error}
        dismiss={() => {
          setError(undefined);
        }}
        level='error'
        title='Er ging iets mis'
      >
        {error?.message}
      </Notification>
      <Slideover
        lightDismiss={!error}
        title={
          paymentSelection && paymentSelection.id
            ? 'Betaling bewerken'
            : 'Betaling toevoegen'
        }
        open={isOpenPayment}
        setOpen={setOpenPayment}
        // isFooterAtBottom
        actions={onRenderPaymentFooterContent()}
      >
        <CreateUpdatePaymentForm
          handleChange={setPaymentSelection}
          payment={paymentSelection}
          isOpenRefundDialog={isOpenRefundDialog}
          setOpenRefundDialog={setOpenRefundDialog}
          setOpenPayment={setOpenPayment}
          setOpenDeletePaymentDialog={setOpenDeletePaymentDialog}
          isOpenDeletePaymentDialog={isOpenDeletePaymentDialog}
          deletePayment={deletePayment}
        />
      </Slideover>
      <Slideover
        title={
          selection && selection.id ? 'Boeking bewerken' : 'Boeking toevoegen'
        }
        description='Voeg een nieuwe boeking toe of bewerk een bestaande boeking.'
        open={isOpen}
        setOpen={setOpen}
        actions={onRenderFooterContent(selection)}
        lightDismiss={!dialogCancelOpen && !isOpenPayment && !error}
      >
        <CreateUpdateForm
          handleChange={setSelection}
          booking={selection}
          openPaymentPanel={() => setOpenPayment(true)}
          setSelectedPayment={setPaymentSelection}
        />
      </Slideover>
      {selection && (
        <Dialog
          open={dialogCancelOpen}
          setOpen={setDialogCancelOpen}
          title='Boeking annuleren'
          icon={
            <ExclamationIcon
              className='h-6 w-6 text-red-600'
              aria-hidden='true'
            />
          }
          description='Je staat op het punt om deze boeking te annuleren. Wil je bij het annuleren reeds betaalde facturen/waarborg terugstorten?'
          actions={
            <div className='space-x-3'>
              <Button
                secondary
                style={{ marginBottom: 2 }}
                onClick={e => {
                  e.preventDefault();
                  cancelBooking({
                    variables: {
                      where: {
                        id: selection.id,
                      },
                      refund: false,
                    },
                  });
                  // dismissPanel();
                }}
              >
                Zonder credit / refund
              </Button>
              <Button
                primary
                style={{ marginBottom: 2 }}
                onClick={e => {
                  e.preventDefault();
                  cancelBooking({
                    variables: {
                      where: {
                        id: selection.id,
                      },
                      refund: true,
                    },
                  });
                  // dismissPanel();
                }}
              >
                Met credit / refund
              </Button>
            </div>
          }
          lightDismiss
        />
      )}
    </div>
  );
};

interface ICreateUpdate {
  booking?: IBooking;
  handleChange?: React.Dispatch<React.SetStateAction<IBooking>>;
  openPaymentPanel?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedPayment?: React.Dispatch<React.SetStateAction<IPayment>>;
}

const CreateUpdateForm: React.FC<ICreateUpdate> = ({
  booking,
  handleChange = () => ({}),
  openPaymentPanel = () => ({}),
  setSelectedPayment = () => ({}),
}) => {
  const [currentDateSelection, setCurrentDateSelection] = useState<
    Moment | undefined
  >();
  const [currentMonth, setCurrentMonth] = useState(moment().startOf('month'));
  const [sendingEmails, setSendingEmails] = useState(false);
  const [editLeavingDate, setEditLeavingDate] = useState(false);
  const [editArrivalDate, setEditArrivalDate] = useState(false);
  const [editPrice, setEditPrice] = useState(false);

  const [dataOptions, setDataOptions] = useState<
    { findManyOptions: any } | undefined
  >();

  const additionalOptions: () => IBookingOption[] = useCallback(() => {
    const unusedOptions: IBookingOption[] = [];

    const currentOptionTypeIDs: number[] = [];

    if (booking && booking.options && booking.options.length > 0) {
      for (let i = 0; i < booking.options.length; i += 1) {
        const currentOption = booking.options[i];
        if (currentOption.option && currentOption.option.id)
          currentOptionTypeIDs.push(currentOption.option.id);
      }
    }

    if (
      dataOptions &&
      dataOptions.findManyOptions &&
      dataOptions.findManyOptions.length > 0
    ) {
      for (let i = 0; i < dataOptions.findManyOptions.length; i += 1) {
        const option = dataOptions.findManyOptions[i];
        if (option && option.id && !currentOptionTypeIDs.includes(option.id)) {
          unusedOptions.push({
            id: -1 - i,
            name: option.name,
            fee: option.fee,
            price: 0,
            amount: 0,
            option: {
              ...option,
              type: option.type === 'amount' ? 'Per stuk' : 'Per persoon',
            },
          });
        }
      }
    }

    return unusedOptions;
  }, [booking, dataOptions]);

  useEffect(() => {
    if (currentDateSelection) {
      handleChange(prevState => {
        let updatedBooking: IBooking | undefined = _.cloneDeep(prevState);

        updatedBooking = {
          ...updatedBooking,
          start: currentDateSelection.format(),
        };

        return updatedBooking;
      });
    }
  }, [currentDateSelection, handleChange]);

  const { data: dataOptionsData } = useQuery(GET_OPTIONS, {
    fetchPolicy: 'network-only',
    onCompleted: d => {
      setDataOptions(d);
    },
  });

  const { data: dataUnits } = useQuery(GET_UNITS);

  const [resendEmails] = useMutation(RESEND_EMAILS);

  const { data: dataPeriods } = useQuery(GET_PERIODS);

  const { data: dataBookingInfo } = useQuery(GET_BOOKING_INFO, {
    variables: {
      where: {
        start: moment(currentMonth).startOf('month').format(),
        end: moment(currentMonth).endOf('month').format(),
        unit: booking && booking.unit ? booking.unit.id : undefined,
      },
    },
    skip: !booking?.unit?.id,
    fetchPolicy: 'network-only',
  });

  const { data: dataCalender, loading: loadingCalender } = useQuery(
    GET_AVAILABILITY,
    {
      variables: {
        where: {
          start: moment(currentMonth).startOf('month').format(),
          end: moment(currentMonth).endOf('month').format(),
          period: booking && booking.period ? booking.period.id : 1,
          persons: booking && booking.persons ? booking.persons : 1,
          unit: booking && booking.unit ? booking.unit.id : 1,
        },
      },
      skip: !(
        booking &&
        booking.period &&
        booking.period.id &&
        booking.unit &&
        booking.unit.id
      ),
      fetchPolicy: 'network-only',
    },
  );

  const setTempUnitSelection = (unit: IUnit | undefined) => {
    handleChange(prevState => ({ ...prevState, unit }));
  };
  const setTempPeriodSelection = (period: IPeriod | undefined) => {
    handleChange(prevState => ({ ...prevState, period }));
  };
  const _onChangeInputDateStart = (value: string) => {
    handleChange(prevState => {
      let updatedBooking: IBooking | undefined = _.cloneDeep(prevState);

      if (updatedBooking) {
        updatedBooking = {
          ...updatedBooking,
          start: moment(value).toISOString(),
        };
      }

      return updatedBooking;
    });
    setEditArrivalDate(false);
  };

  const _onChangeInputDateEnd = (value: string) => {
    handleChange(prevState => {
      let updatedBooking: IBooking | undefined = _.cloneDeep(prevState);

      if (updatedBooking) {
        updatedBooking = {
          ...updatedBooking,
          end: moment(value).toISOString(),
        };
      }

      return updatedBooking;
    });

    setEditLeavingDate(false);
  };

  const _onChangeInputSendPayment = (checked: boolean) => {
    handleChange(prevState => {
      let updatedBooking: IBooking | undefined = _.cloneDeep(prevState);

      if (!updatedBooking) updatedBooking = {};

      updatedBooking = {
        ...updatedBooking,
        sendPayment: checked,
      };

      return updatedBooking;
    });
  };

  const _onChangeInputCreateUser = (checked: boolean) => {
    handleChange(prevState => {
      let updatedBooking: IBooking | undefined = _.cloneDeep(prevState);
      let updatedUser: IUser | undefined = _.cloneDeep(updatedBooking.user);
      if (!updatedBooking) updatedBooking = {};
      if (!updatedUser) {
        updatedUser = {};
      }

      updatedUser.createNew = checked;

      updatedBooking = {
        ...updatedBooking,
        user: updatedUser,
      };

      return updatedBooking;
    });
  };

  const _onChangeInputEmail = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { target } = event;
    const { value } = target;

    handleChange(prevState => {
      let updatedBooking: IBooking | undefined = _.cloneDeep(prevState);

      if (!updatedBooking)
        updatedBooking = {
          user: {},
        };

      updatedBooking = {
        ...updatedBooking,
        user: {
          ...updatedBooking.user,
          email: value,
          emailChanged: true,
        },
      };

      return updatedBooking;
    });
  };

  const _onChangeInputPersons = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { target } = event;
    const { value } = target;
    let numberValue = Number(removeLeadingZeros(value));

    handleChange(prevState => {
      let updatedBooking: IBooking | undefined = _.cloneDeep(prevState);

      if (!updatedBooking) updatedBooking = {};

      if (booking && booking.unit && booking.unit.maxPersons) {
        if (Number(value) > booking.unit.maxPersons)
          numberValue = booking.unit.maxPersons;
      }

      updatedBooking = {
        ...updatedBooking,
        persons: numberValue,
      };

      return updatedBooking;
    });
  };

  const _onChangeInputChildren = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { target } = event;
    const { value } = target;
    const numberValue = Number(removeLeadingZeros(value));

    handleChange(prevState => {
      let updatedBooking: IBooking | undefined = _.cloneDeep(prevState);

      if (!updatedBooking) updatedBooking = {};

      updatedBooking = {
        ...updatedBooking,
        children: numberValue,
      };

      return updatedBooking;
    });
  };

  const _onChangeInputAdults = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { target } = event;
    const { value } = target;
    const numberValue = Number(removeLeadingZeros(value));

    handleChange(prevState => {
      let updatedBooking: IBooking | undefined = _.cloneDeep(prevState);

      if (!updatedBooking) updatedBooking = {};

      updatedBooking = {
        ...updatedBooking,
        adults: numberValue,
      };

      return updatedBooking;
    });
  };

  const _onChangeInputRemarks = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { target } = event;
    const { value } = target;

    handleChange(prevState => {
      let updatedBooking: IBooking | undefined = _.cloneDeep(prevState);

      if (!updatedBooking) updatedBooking = {};

      updatedBooking = {
        ...updatedBooking,
        remarks: value,
      };

      return updatedBooking;
    });
  };

  const _onChangeInputPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { value } = target;

    handleChange(prevState => {
      let updatedBooking: IBooking | undefined = _.cloneDeep(prevState);

      if (!updatedBooking) updatedBooking = {};

      updatedBooking = {
        ...updatedBooking,
        price: parseFloat(value),
      };

      return updatedBooking;
    });
  };

  const _onChangeInputFirstName = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { target } = event;
    const { value } = target;

    handleChange(prevState => {
      let updatedBooking: IBooking | undefined = _.cloneDeep(prevState);

      if (!updatedBooking) updatedBooking = {};

      if (
        updatedBooking &&
        updatedBooking.user &&
        updatedBooking.user.profile
      ) {
        updatedBooking.user.profile = {
          ...updatedBooking.user.profile,
          firstName: value,
        };
      } else if (updatedBooking && updatedBooking.user) {
        updatedBooking.user = {
          ...updatedBooking.user,
          profile: {
            firstName: value,
          },
        };
      } else {
        updatedBooking = {
          ...updatedBooking,
          user: {
            profile: {
              firstName: value,
            },
          },
        };
      }

      return updatedBooking;
    });
  };

  const _onChangeInputLastName = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { target } = event;
    const { value } = target;

    handleChange(prevState => {
      let updatedBooking: IBooking | undefined = _.cloneDeep(prevState);

      if (!updatedBooking) updatedBooking = {};

      if (
        updatedBooking &&
        updatedBooking.user &&
        updatedBooking.user.profile
      ) {
        updatedBooking.user.profile = {
          ...updatedBooking.user.profile,
          lastName: value,
        };
      } else if (updatedBooking && updatedBooking.user) {
        updatedBooking.user = {
          ...updatedBooking.user,
          profile: {
            lastName: value,
          },
        };
      } else {
        updatedBooking = {
          ...updatedBooking,
          user: {
            profile: {
              lastName: value,
            },
          },
        };
      }

      return updatedBooking;
    });
  };

  const _onChangeInputPhone = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { target } = event;
    const { value } = target;
    handleChange(prevState => {
      let updatedBooking: IBooking | undefined = _.cloneDeep(prevState);

      if (!updatedBooking) updatedBooking = {};

      if (
        updatedBooking &&
        updatedBooking.user &&
        updatedBooking.user.profile
      ) {
        updatedBooking.user.profile = {
          ...updatedBooking.user.profile,
          phone: value,
        };
      } else if (updatedBooking && updatedBooking.user) {
        updatedBooking.user = {
          ...updatedBooking.user,
          profile: {
            phone: value,
          },
        };
      } else {
        updatedBooking = {
          ...updatedBooking,
          user: {
            profile: {
              phone: value,
            },
          },
        };
      }

      return updatedBooking;
    });
  };

  const _onChangeInputMobile = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { target } = event;
    const { value } = target;
    handleChange(prevState => {
      let updatedBooking: IBooking | undefined = _.cloneDeep(prevState);

      if (!updatedBooking) updatedBooking = {};

      if (
        updatedBooking &&
        updatedBooking.user &&
        updatedBooking.user.profile
      ) {
        updatedBooking.user.profile = {
          ...updatedBooking.user.profile,
          mobile: value,
        };
      } else if (updatedBooking && updatedBooking.user) {
        updatedBooking.user = {
          ...updatedBooking.user,
          profile: {
            mobile: value,
          },
        };
      } else {
        updatedBooking = {
          ...updatedBooking,
          user: {
            profile: {
              mobile: value,
            },
          },
        };
      }

      return updatedBooking;
    });
  };

  const _onChangeInputCompany = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { target } = event;
    const { value } = target;

    handleChange(prevState => {
      let updatedBooking: IBooking | undefined = _.cloneDeep(prevState);
      if (!updatedBooking) updatedBooking = {};

      if (
        updatedBooking &&
        updatedBooking.user &&
        updatedBooking.user.profile
      ) {
        updatedBooking.user.profile = {
          ...updatedBooking.user.profile,
          company: value,
        };
      } else if (updatedBooking && updatedBooking.user) {
        updatedBooking.user = {
          ...updatedBooking.user,
          profile: {
            company: value,
          },
        };
      } else {
        updatedBooking = {
          ...updatedBooking,
          user: {
            profile: {
              company: value,
            },
          },
        };
      }

      return updatedBooking;
    });
  };

  const _onChangeInputAddress = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { target } = event;
    const { value, id } = target;

    handleChange(prevState => {
      let updatedBooking: IBooking | undefined = _.cloneDeep(prevState);
      if (!updatedBooking) updatedBooking = {};

      if (
        updatedBooking &&
        updatedBooking.user &&
        updatedBooking.user.profile &&
        updatedBooking.user.profile.address
      ) {
        updatedBooking.user.profile.address = {
          ...updatedBooking.user.profile.address,
          [id]: value,
        };
      } else if (
        updatedBooking &&
        updatedBooking.user &&
        updatedBooking.user.profile
      ) {
        updatedBooking.user.profile = {
          ...updatedBooking.user.profile,
          address: {
            [id]: value,
          },
        };
      } else if (updatedBooking && updatedBooking.user) {
        updatedBooking.user = {
          ...updatedBooking.user,
          profile: {
            address: {
              [id]: value,
            },
          },
        };
      } else {
        updatedBooking = {
          ...updatedBooking,
          user: {
            profile: {
              address: {
                [id]: value,
              },
            },
          },
        };
      }

      return updatedBooking;
    });
  };

  const _onChangeInputVat = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { target } = event;
    const { value } = target;

    handleChange(prevState => {
      let updatedBooking: IBooking | undefined = _.cloneDeep(prevState);

      if (!updatedBooking) updatedBooking = {};

      if (
        updatedBooking &&
        updatedBooking.user &&
        updatedBooking.user.profile
      ) {
        updatedBooking.user.profile = {
          ...updatedBooking.user.profile,
          vat: value,
        };
      } else if (updatedBooking && updatedBooking.user) {
        updatedBooking.user = {
          ...updatedBooking.user,
          profile: {
            vat: value,
          },
        };
      } else {
        updatedBooking = {
          ...updatedBooking,
          user: {
            profile: {
              vat: value,
            },
          },
        };
      }

      return updatedBooking;
    });
  };

  const parseUnitOptions = (units: IUnit[]) => {
    const parsedUnits: ComboboxItem[] = [];

    for (let i = 0; i < units.length; i += 1) {
      const unit = units[i];
      if (unit.id && unit.title)
        parsedUnits.push({ id: unit.id, name: unit.title });
    }

    return parsedUnits;
  };

  const parsePeriodOptions = (periods: IPeriod[]) => {
    const parsedPeriods: ComboboxItem[] = [];

    for (let i = 0; i < periods.length; i += 1) {
      const period = periods[i];
      if (period.id && period.name)
        parsedPeriods.push({ id: period.id, name: period.name });
    }

    return parsedPeriods;
  };

  const resendEmailsNow = () => {
    if (!booking || !booking.id) return;

    setSendingEmails(true);

    resendEmails({ variables: { where: { id: booking.id } } })
      .then(data => {
        setSendingEmails(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  // const tempPeriodSelection = booking?.period?.id;

  const onChangeOptionInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    optionID: number,
  ) => {
    const { target } = event;
    const { value, id } = target;

    let parsedValue: number | undefined;
    if (id === 'fee' && typeof value === 'string') {
      parsedValue = parseFloat(value);
    }
    if (id === 'amount' && typeof value === 'string') {
      parsedValue = parseInt(value, 10);
    }
    handleChange(prevState => {
      let updatedBooking: IBooking | undefined = _.cloneDeep(prevState);

      if (!updatedBooking) updatedBooking = {};

      const options: IBookingOption[] = updatedBooking.options || [];

      for (let i = 0; i < options.length; i += 1) {
        const currentOption = options[i];
        if (currentOption.id === optionID) {
          switch (id) {
            case 'fee':
              currentOption.fee = parsedValue;
              currentOption.price = parsedValue
                ? parsedValue * (currentOption.amount || 0)
                : 0;
              break;
            case 'amount':
              currentOption.amount = parsedValue;
              currentOption.price = parsedValue
                ? parsedValue * (currentOption.fee || 0)
                : 0;
              break;
            default:
              break;
          }
        }
      }

      updatedBooking.options = options;

      return updatedBooking;
    });
  };

  const addAdditionalOption = (option: IBookingOption) => {
    handleChange(prevState => {
      let updatedBooking: IBooking | undefined = _.cloneDeep(prevState);

      if (!updatedBooking) updatedBooking = {};

      const options: IBookingOption[] = updatedBooking.options || [];

      const newOption = _.cloneDeep(option);

      options.push(newOption);

      updatedBooking.options = options;

      return updatedBooking;
    });
  };

  const getBookingPriceIncludingOptions = (inputData: IBooking) => {
    let price = 0;
    if (inputData && inputData.options) {
      for (let i = 0; i < inputData.options.length; i += 1) {
        const option = inputData.options[i];

        if (option && option.price) {
          price += option.price;
        }
      }
    }

    if (inputData.price) {
      price += inputData.price;
    }

    return price;
  };

  return (
    <form className='space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0'>
      {booking && booking.id && (
        <>
          <div className='space-y-1 px-4 flex items-center gap-2 sm:px-6 sm:py-5'>
            <span className='col-span-1'>Status: </span>
            <div>
              <Status status={booking ? getBookingStatus(booking) : 'open'} />
            </div>
          </div>
          <dl>
            <div className='bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Unit</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {booking && booking.unit && booking.unit.title}
              </dd>
            </div>
            <div className='bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Periode</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {booking && booking.period && booking.period.name}
              </dd>
            </div>
            <div className='bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>
                Aankomst{' '}
                <button
                  type='button'
                  onClick={() => {
                    setEditArrivalDate(!editArrivalDate);
                  }}
                >
                  <FontAwesomeIcon icon={faPen} />
                </button>
              </dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {editArrivalDate && (
                  <Input
                    name='dateDue'
                    id='dateDue'
                    type='date'
                    value={
                      booking && booking.start
                        ? moment(booking.start).format('YYYY-MM-DD')
                        : ''
                    }
                    onChange={e => {
                      const { target } = e;
                      const { value } = target;
                      _onChangeInputDateStart(value);
                    }}
                  />
                )}
                {!editArrivalDate &&
                  booking &&
                  booking.start &&
                  moment(booking.start).format('DD-MM-YYYY (dddd)')}
              </dd>
            </div>
            <div className='bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>
                Vertrek{' '}
                <button
                  type='button'
                  onClick={() => {
                    setEditLeavingDate(!editLeavingDate);
                  }}
                >
                  <FontAwesomeIcon icon={faPen} />
                </button>
              </dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {editLeavingDate && (
                  <Input
                    name='dateDue'
                    id='dateDue'
                    type='date'
                    value={
                      booking && booking.end
                        ? moment(booking.end).format('YYYY-MM-DD')
                        : ''
                    }
                    onChange={e => {
                      const { target } = e;
                      const { value } = target;
                      _onChangeInputDateEnd(value);
                    }}
                  />
                )}
                {!editLeavingDate &&
                  booking &&
                  booking.end &&
                  moment(booking.end).format('DD-MM-YYYY (dddd)')}
              </dd>
            </div>
            <div className='bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>
                Prijs (excl. opties){' '}
                <button
                  type='button'
                  onClick={() => {
                    setEditPrice(!editPrice);
                  }}
                >
                  <FontAwesomeIcon icon={faPen} />
                </button>
              </dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {editPrice && (
                  <Input
                    type='number'
                    onChange={_onChangeInputPrice}
                    value={booking && booking.price}
                  />
                )}
                {!editPrice && (
                  <>€ {booking && booking.price && booking.price}</>
                )}
              </dd>
            </div>
            <div className='bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>
                Prijs (inclusief. opties)
              </dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                € {getBookingPriceIncludingOptions(booking)}
              </dd>
            </div>
            <div className='bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Volwassenen</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {booking && booking.adults && booking.adults}
              </dd>
            </div>
            <div className='bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Kinderen</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {booking && booking.children && booking.children}
              </dd>
            </div>
            <div className='bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>ID</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {booking && booking.id && booking.id}
              </dd>
            </div>
            <div className='bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Aangemaakt</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {booking &&
                  booking.createdAt &&
                  moment(booking.createdAt).format('DD-MM-YYYY HH:MM (dddd)')}
              </dd>
            </div>
            {booking &&
              booking.externalBooking &&
              booking.externalBooking.source && (
                <div className='bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                  <dt className='text-sm font-medium text-gray-500'>
                    Externe booking
                  </dt>
                  <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                    {booking.externalBooking.source.title}
                  </dd>
                </div>
              )}
          </dl>
        </>
      )}

      {booking && booking.id && (
        <div className='space-y-1 px-4 sm:space-y-0 sm:px-6 sm:py-5'>
          <div className='mb-3'>
            <label
              htmlFor='body'
              className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
            >
              {' '}
              Betalingen:{' '}
            </label>
            <Button
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedPayment({
                  type: {
                    id: 4,
                    label: 'Totaal',
                  },
                  vatPercentage: 6,
                  dateDue: moment().format('YYYY-MM-DD'),
                });
                openPaymentPanel(true);
              }}
              primary
              className='mt-2'
            >
              + toevoegen
            </Button>
          </div>
          <div>
            {booking && (
              <PaymentTable
                booking={booking}
                openDialog={openPaymentPanel}
                setSelection={setSelectedPayment}
              />
            )}
          </div>
        </div>
      )}

      <div className='space-y-1 px-4 sm:space-y-0 sm:px-6 sm:py-5'>
        <div className='mb-3'>
          <label
            htmlFor='body'
            className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
          >
            {' '}
            Gegevens:{' '}
          </label>
        </div>
        <div className='grid grid-cols-4 gap-2'>
          <div className='col-span-2'>
            <label>Unit: *</label>
            <Combobox
              items={
                dataUnits && dataUnits.findManyUnits
                  ? parseUnitOptions(dataUnits.findManyUnits)
                  : []
              }
              selected={
                booking && booking.unit
                  ? { id: booking.unit.id!, name: booking.unit.title! }
                  : undefined
              }
              /* query={tempUnitValue}
              setQuery={value => {
                setTempUnitValue(value);
                setTempUnitSelection(undefined);
              }} */
              setSelected={value => {
                // setTempUnitValue('');
                let unit: IUnit | undefined;
                if (dataUnits && dataUnits.findManyUnits) {
                  for (let i = 0; i < dataUnits.findManyUnits.length; i += 1) {
                    if (dataUnits.findManyUnits[i].id === value.id)
                      unit = dataUnits.findManyUnits[i];
                  }
                }

                setTempUnitSelection(unit);
                setCurrentDateSelection(undefined);
              }}
              placeholder='zoeken...'
              autoComplete='off'
            />
          </div>
          <div className='col-span-2'>
            <label>Periode: *</label>
            <Combobox
              items={
                dataPeriods && dataPeriods.findManyPeriods
                  ? parsePeriodOptions(dataPeriods.findManyPeriods)
                  : []
              }
              selected={
                booking && booking.period
                  ? { id: booking.period.id!, name: booking.period.name! }
                  : undefined
              }
              /* query={tempPeriodValue}
              setQuery={value => {
                setTempPeriodValue(value);
                setTempPeriodSelection(undefined);
              }} */
              setSelected={value => {
                // setTempPeriodValue('');
                let period: IPeriod | undefined;
                if (dataPeriods && dataPeriods.findManyPeriods) {
                  for (
                    let i = 0;
                    i < dataPeriods.findManyPeriods.length;
                    i += 1
                  ) {
                    if (dataPeriods.findManyPeriods[i].id === value.id)
                      period = dataPeriods.findManyPeriods[i];
                  }
                }

                setTempPeriodSelection(period);
                setCurrentDateSelection(undefined);
              }}
              placeholder='zoeken...'
              autoComplete='off'
            />
          </div>
        </div>
      </div>
      <div
        className='space-y-1 px-4 sm:space-y-0 sm:px-6 sm:py-5'
        style={{
          display: booking && booking.id ? 'none' : 'block',
          opacity: booking && booking.unit && booking.period ? 1 : 0.5,
        }}
      >
        <div className='flex items-center text-center text-gray-900'>
          <button
            type='button'
            onClick={() => {
              setCurrentMonth(moment(currentMonth).subtract(1, 'month'));
            }}
            className='-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500'
          >
            <span className='sr-only'>Previous month</span>
            <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
          </button>
          <div className='flex-auto flex font-semibold  justify-center'>
            {currentMonth.format('MMMM YYYY')}
            {loadingCalender && (
              <svg
                className='animate-spin ml-2 mr-3 h-6 w-6 text-red'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
              >
                <circle
                  className='opacity-25'
                  cx='12'
                  cy='12'
                  r='10'
                  stroke='currentColor'
                  strokeWidth='4'
                />
                <path
                  className='opacity-75'
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                />
              </svg>
            )}
          </div>
          <button
            type='button'
            onClick={() => {
              setCurrentMonth(moment(currentMonth).add(1, 'month'));
            }}
            className='-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500'
          >
            <span className='sr-only'>Next month</span>
            <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
          </button>
        </div>
        <Calender
          currentSelection={currentDateSelection}
          setSelection={date => {
            setCurrentDateSelection(date);
          }}
          currentSelectionDuration={
            booking && booking.period ? booking.period.duration : 0
          }
          month={currentMonth.month() + 1}
          year={currentMonth.year()}
          dateValidation={
            dataCalender && dataCalender.getAvailability
              ? dataCalender.getAvailability
              : []
          }
          bookingInfo={
            dataBookingInfo && dataBookingInfo.getBookingInfo
              ? dataBookingInfo.getBookingInfo
              : []
          }
          loading={loadingCalender}
        />
      </div>
      <div className='space-y-1 px-4 sm:space-y-0 sm:px-6 sm:py-5'>
        {!booking ||
          (!booking.id && (
            <div className='grid grid-cols-4 gap-2 pt-5'>
              <div className='col-span-2'>
                <Switch
                  label='Betalingen automatisch aanmaken?'
                  // style={{ width: '2.5rem' }}
                  onChange={_onChangeInputSendPayment}
                  checked={!!(booking && booking.sendPayment)}
                />
              </div>
            </div>
          ))}

        <div className='grid grid-cols-4 gap-2 pt-5'>
          <div className='col-span-2'>
            <label>Email</label>
            <Input
              name='user.email'
              id='user.email'
              value={_.get(booking, 'user.email', '')}
              onChange={_onChangeInputEmail}
              type='text'
            />
          </div>
          <div className='col-span-2'>
            <label>
              Aantal personen * (max:{' '}
              {booking && booking.unit && booking.unit.maxPersons} )
            </label>
            <Input
              name='user.persons'
              id='user.persons'
              value={_.get(booking, 'persons', '')}
              onChange={_onChangeInputPersons}
              type='number'
              max={
                booking && booking.unit && booking.unit.maxPersons
                  ? booking.unit.maxPersons
                  : undefined
              }
              min={
                booking && booking.unit ? booking.unit.minPersons : undefined
              }
            />
          </div>
        </div>
        <div className='grid grid-cols-4 gap-2 pt-5'>
          <div className='col-span-2'>
            <label>Aantal kinderen:</label>
            <Input
              name='user.children'
              id='user.children'
              value={_.get(booking, 'children', '')}
              onChange={_onChangeInputChildren}
              type='number'
            />
          </div>
          <div className='col-span-2'>
            <label>Aantal volwassenen:</label>
            <Input
              name='user.adults'
              id='user.adults'
              value={_.get(booking, 'adults', '')}
              onChange={_onChangeInputAdults}
              type='number'
            />
          </div>
        </div>
        <div className='grid grid-cols-4 gap-2 pt-5'>
          <div className='col-span-2'>
            <label>Voornaam *</label>
            <Input
              name='user.profile.firstName'
              id='user.profile.firstName'
              value={_.get(booking, 'user.profile.firstName', '')}
              onChange={_onChangeInputFirstName}
              type='text'
            />
          </div>
          <div className='col-span-2'>
            <label>Achternaam *</label>
            <Input
              name='user.lastName'
              id='user.lastName'
              value={_.get(booking, 'user.profile.lastName', '')}
              onChange={_onChangeInputLastName}
              type='text'
            />
          </div>
        </div>
        <div className='grid grid-cols-4 gap-2 pt-5'>
          <div className='col-span-2'>
            <label>Telefoon</label>
            <Input
              name='user.profile.firstName'
              id='user.profile.firstName'
              value={_.get(booking, 'user.profile.phone', '')}
              onChange={_onChangeInputPhone}
              type='tel'
            />
          </div>
          <div className='col-span-2'>
            <label>Mobiel </label>
            <Input
              name='user.lastName'
              id='user.lastName'
              value={_.get(booking, 'user.profile.mobile', '')}
              onChange={_onChangeInputMobile}
              type='tel'
            />
          </div>
        </div>
        <div className='grid grid-cols-4 gap-2 pt-5'>
          <div className='col-span-2'>
            <label>Straat</label>
            <Input
              name='user.profile.address.street'
              id='street'
              value={_.get(booking, 'user.profile.address.street', '')}
              onChange={_onChangeInputAddress}
              type='text'
            />
          </div>
          <div className='col-span-2'>
            <label>Postcode</label>
            <Input
              name='user.profile.address.postcode'
              id='postcode'
              value={_.get(booking, 'user.profile.address.postcode', '')}
              onChange={_onChangeInputAddress}
              type='text'
            />
          </div>
        </div>
        <div className='grid grid-cols-4 gap-2 pt-5'>
          <div className='col-span-2'>
            <label>Stad/Gemeente</label>
            <Input
              name='user.profile.address.city'
              id='city'
              onChange={_onChangeInputAddress}
              value={_.get(booking, 'user.profile.address.city', '')}
              type='text'
            />
          </div>
          <div className='col-span-2'>
            <label>Land</label>
            <Input
              name='user.profile.address.country'
              id='country'
              onChange={_onChangeInputAddress}
              value={_.get(booking, 'user.profile.address.country', '')}
              type='text'
            />
          </div>
        </div>

        <div className='grid grid-cols-4 gap-2 pt-5'>
          <div className='col-span-2'>
            <label>Bedrijf</label>
            <Input
              name='user.profile.firstName'
              id='user.profile.firstName'
              value={
                booking && booking.user && booking.user.profile
                  ? booking.user.profile.company
                  : ''
              }
              onChange={_onChangeInputCompany}
              type='text'
            />
          </div>
          <div className='col-span-2'>
            <label>BTW Nummer</label>
            <Input
              name='user.lastName'
              id='user.lastName'
              value={
                booking && booking.user && booking.user.profile
                  ? booking.user.profile.vat
                  : ''
              }
              onChange={_onChangeInputVat}
              type='text'
            />
          </div>
        </div>
        <div className='grid grid-cols-4 gap-2 pt-5'>
          <div className='col-span-4'>
            <label>Opmerkingen</label>
            <Textarea
              rows={5}
              value={booking && booking.remarks ? booking.remarks : ''}
              onChange={_onChangeInputRemarks}
            />
          </div>
        </div>

        {booking && booking.status !== 'canceled' && (
          <div className='grid grid-cols-4 gap-2 pt-5'>
            <div className='col-span-4'>
              {booking && booking.id && (
                <Button onClick={resendEmailsNow} primary size='lg'>
                  Email (opnieuw) verzenden
                  {sendingEmails && (
                    <svg
                      className='animate-spin ml-2 mr-3 h-6 w-6 text-red'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                    >
                      <circle
                        className='opacity-25'
                        cx='12'
                        cy='12'
                        r='10'
                        stroke='currentColor'
                        strokeWidth='4'
                      />
                      <path
                        className='opacity-75'
                        fill='currentColor'
                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                      />
                    </svg>
                  )}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
      <div className='space-y-1 px-4 sm:space-y-0 sm:px-6 sm:py-5'>
        <div className='mb-3'>
          <label
            htmlFor='body'
            className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
          >
            {' '}
            Opties:{' '}
          </label>
        </div>
        {booking && booking.options && booking.options.length > 0 && (
          <table className='min-w-full divide-y divide-gray-300'>
            <thead>
              <tr>
                <th
                  scope='col'
                  className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0'
                >
                  Naam
                </th>
                <th
                  scope='col'
                  className='py-3.5 px-3 text-left text-sm font-semibold text-gray-900'
                >
                  Type
                </th>
                <th
                  scope='col'
                  className='py-3.5 px-3 text-left text-sm font-semibold text-gray-900'
                >
                  Prijs
                </th>
                <th
                  scope='col'
                  className='py-3.5 px-3 text-left text-sm font-semibold text-gray-900'
                >
                  Aantal
                </th>
                <th
                  scope='col'
                  className='py-3.5 px-3 text-left text-sm font-semibold text-gray-900'
                >
                  Totaal
                </th>
                <th
                  scope='col'
                  className='relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0'
                >
                  <span className='sr-only'>Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200'>
              {booking.options.map((option, index) => {
                return (
                  <tr key={`option_${option.id}_${index}`}>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0'>
                      {option.name}
                    </td>
                    <td className='whitespace-nowrap py-4 px-3 text-sm text-gray-500'>
                      {option?.option?.type}
                    </td>
                    <td className='whitespace-nowrap py-4 px-3 text-sm text-gray-500'>
                      <Input
                        name='fee'
                        id='fee'
                        value={option && option.fee ? option.fee : ''}
                        onChange={e => onChangeOptionInput(e, option.id!)}
                        type='number'
                        step='.01'
                      />
                    </td>
                    <td className='whitespace-nowrap py-4 px-3 text-sm text-gray-500'>
                      <Input
                        name='amount'
                        id='amount'
                        value={option && option.amount ? option.amount : ''}
                        onChange={e => onChangeOptionInput(e, option.id!)}
                        type='number'
                      />
                    </td>
                    <td className='whitespace-nowrap py-4 px-3 text-sm text-gray-500'>
                      € {option.price}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <div className='space-x-2'>
          {additionalOptions().map(option => {
            return (
              <Button
                onClick={() => addAdditionalOption(option)}
                key={option.id}
                primary
              >
                <div>{option.name}</div>
                <div className='ml-2'>+</div>
              </Button>
            );
          })}
        </div>
      </div>
    </form>
  );
};

export default Bookings;
