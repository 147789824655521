import { gql } from '@apollo/client';

export const GET_BOOKING = gql`
  query findOneBooking($where: BookingWhereUniqueInput) {
    findOneBooking(where: $where) {
      id
      createdAt
      updatedAt
      status
      remarks
      options {
        id
        fee
        price
        amount
        name
        option {
          id
          type
          fee
          description
        }
      }
      user {
        id
        active
        email
        name
        profile {
          id
          firstName
          lastName
          phone
          mobile
          vat
          address {
            id
            street
            city
            postcode
            country
          }
        }
      }
      body
      payments {
        id
        uuid
        createdAt
        updatedAt
        status
        title
        paymentId
        invoiceId
        creditId
        refundId
        checkoutId
        isRefunded
        isPaid
        amount
        vatAmount
        vatPercentage
        includesGuarantee
        guaranteeAmount
        setPaidOnCreaction
        provider
        stripe {
          invoice
          credit
          intent
          refund
        }
        mollie {
          invoice
          credit
          refund
          intent
        }
        type {
          id
          label
          slug
        }
      }
      unit {
        id
        title
        minPersons
        maxPersons
      }
      period {
        id
        name
        startDay
        duration
      }
      persons
      manual
      temporary
      start
      end
      externalBooking {
        id
        start
        end
        uid
        description
        source {
          id
          title
          url
        }
      }
    }
  }
`;

export const GET_BOOKINGS = gql`
  query findManyBookings(
    $where: BookingWhereInput
    $orderBy: BookingOrderByInput
    $skip: Int
    $take: Int
  ) {
    findManyBookings(
      where: $where
      orderBy: $orderBy
      skip: $skip
      take: $take
    ) {
      id
      createdAt
      updatedAt
      remarks
      options {
        id
        fee
        price
        amount
        name
        option {
          id
          type
          fee
          description
        }
      }
      user {
        id
        active
        email
        name
        profile {
          id
          firstName
          lastName
          phone
          mobile
          vat
          company
          address {
            id
            street
            city
            postcode
            country
          }
        }
      }
      body
      status
      paymentStatus
      payments {
        id
        uuid
        createdAt
        updatedAt
        status
        title
        paymentId
        invoiceId
        creditId
        refundId
        checkoutId
        isRefunded
        isPaid
        amount
        vatAmount
        vatPercentage
        includesGuarantee
        guaranteeAmount
        setPaidOnCreaction
        dateDue
        auto
        provider
        stripe {
          invoice
          credit
          intent
          refund
        }
        mollie {
          invoice
          credit
          refund
          intent
        }
        type {
          id
          label
          slug
        }
      }
      unit {
        id
        title
        minPersons
        maxPersons
      }
      period {
        id
        name
        startDay
        duration
      }
      persons
      adults
      children
      price
      manual
      temporary
      start
      end
      externalBooking {
        id
        start
        end
        uid
        description
        source {
          id
          title
          url
        }
      }
    }
  }
`;

export const CREATE_BOOKING = gql`
  mutation createBooking($data: BookingCreateInput) {
    createBooking(data: $data) {
      id
      createdAt
      updatedAt
      remarks
      options {
        id
        fee
        price
        amount
        name
        option {
          id
          type
          fee
          description
        }
      }
      user {
        id
        active
        email
        name
        profile {
          id
          firstName
          lastName
          phone
          mobile
          vat
          address {
            id
            street
            city
            postcode
            country
          }
        }
      }
      body
      payments {
        id
        createdAt
        updatedAt
        status
        title
        paymentId
        invoiceId
        creditId
        refundId
        checkoutId
        isRefunded
        isPaid
        canceled
        amount
        vatAmount
        vatPercentage
        includesGuarantee
        guaranteeAmount
        setPaidOnCreaction
        dateDue
        auto
        type {
          id
          label
          slug
        }
        provider
        stripe {
          invoice
          credit
          intent
          refund
        }
        mollie {
          invoice
          credit
          refund
          intent
        }
      }
      unit {
        id
        title
        minPersons
        maxPersons
      }
      period {
        id
        name
        startDay
        duration
      }
      persons
      price
      manual
      temporary
      start
      end
      externalBooking {
        id
        start
        end
        uid
        description
        source {
          id
          title
          url
        }
      }
    }
  }
`;

export const UPDATE_BOOKING = gql`
  mutation updateBooking(
    $data: BookingUpdateInput
    $where: BookingWhereUniqueInput
  ) {
    updateBooking(data: $data, where: $where) {
      id
      createdAt
      updatedAt
      remarks
      options {
        id
        fee
        price
        amount
        name
        option {
          id
          type
          fee
          description
        }
      }
      user {
        id
        active
        email
        name
        profile {
          id
          firstName
          lastName
          phone
          mobile
          vat
          address {
            id
            street
            city
            postcode
            country
          }
        }
      }
      body
      payments {
        id
        uuid
        createdAt
        updatedAt
        status
        title
        paymentId
        invoiceId
        creditId
        refundId
        checkoutId
        isRefunded
        isPaid
        canceled
        amount
        vatAmount
        vatPercentage
        includesGuarantee
        guaranteeAmount
        setPaidOnCreaction
        dateDue
        auto
        type {
          id
          label
          slug
        }
        provider
        stripe {
          invoice
          credit
          intent
          refund
        }
        mollie {
          invoice
          credit
          refund
          intent
        }
      }
      unit {
        id
        title
        minPersons
        maxPersons
      }
      period {
        id
        name
        startDay
        duration
      }
      persons
      price
      manual
      temporary
      start
      end
      externalBooking {
        id
        start
        end
        uid
        description
        source {
          id
          title
          url
        }
      }
    }
  }
`;

export const DELETE_BOOKING = gql`
  mutation deleteBooking($where: BookingWhereUniqueInput) {
    deleteBooking(where: $where) {
      count
    }
  }
`;

export const DELETE_BOOKINGS = gql`
  mutation deleteManyBookings($where: BookingWhereInput) {
    deleteManyBookings(where: $where) {
      count
    }
  }
`;

export const RESEND_EMAILS = gql`
  mutation resendEmails($where: BookingWhereUniqueInput) {
    resendEmails(where: $where) {
      count
    }
  }
`;

export const CANCEL_BOOKING = gql`
  mutation cancelBooking($where: BookingWhereUniqueInput, $refund: Boolean) {
    cancelBooking(where: $where, refund: $refund) {
      id
      createdAt
      updatedAt
      remarks
      user {
        id
        active
        email
        name
        profile {
          id
          firstName
          lastName
          phone
          mobile
          address {
            id
            street
            city
            postcode
            country
          }
        }
      }
      body
      payments {
        id
        uuid
        createdAt
        updatedAt
        status
        title
        paymentId
        invoiceId
        creditId
        refundId
        checkoutId
        isRefunded
        isPaid
        canceled
        amount
        vatAmount
        vatPercentage
        dateDue
        auto
        type {
          id
          label
          slug
        }
        provider
        stripe {
          invoice
          credit
          intent
          refund
        }
        mollie {
          invoice
          credit
          refund
          intent
        }
      }
      unit {
        id
        title
        minPersons
        maxPersons
      }
      period {
        id
        name
        startDay
        duration
      }
      persons
      price
      manual
      temporary
      start
      end
    }
  }
`;
